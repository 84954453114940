import React, { useEffect, useRef, useState, useContext, useCallback } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SVG from 'react-inlinesvg';
import { AnimatePresence, motion } from 'framer-motion';

import focusTrap from '@base/utils/helpers/focus-trap';

import closeIcon from '@img/icons/close.svg';
import getLocationContent, { centerMap } from '@/utils/helpers/get-location-content';
import { MapContext } from '@/utils/contexts/map-context';
import MapSlider from './slider';
import MapLocationContent from './location-content';
import MapLocationVisual from './location-visual';

function MapModal({ data, pageContext, strings, categories }) {
  const modalWrapper = useRef(null);
  const { modalOpen, type, modal, prevModal, nextModal } = useContext(MapContext);
  const [isModalOpen, setModalOpen] = modalOpen;
  const [modalType, setModalType] = type;
  const [modalData, setModalData] = modal;
  const [prevModalData, setPrevModalData] = prevModal;
  const [nextModalData, setNextModalData] = nextModal;
  const [currentModalData, setCurrentModalData] = useState(null);
  const [displayModalData, setDisplayModalData] = useState(false);

  const locationContentHandler = getLocationContent();

  // Get the Current location data
  useEffect(() => {
    setDisplayModalData(false);
    const elementIndex = data.map((x) => x.value).indexOf(modalData);
    let elementData = data.find((x) => x.value === modalData);
    elementData = { index: elementIndex, ...elementData };
    setTimeout(() => {
      setCurrentModalData(elementData);
      setDisplayModalData(true);
    }, 500);
  }, [modalData]);

  // Close Modal
  const close = useCallback(() => {
    if (document?.querySelector('.map-wrapper')) {
      centerMap();
    }
    setModalOpen(false);
  }, []);

  const handleEscape = useCallback(
    (event) => {
      // Escape
      if (event.keyCode === 27) {
        close();
      }
    },
    [close],
  );

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('keydown', handleEscape, false);
      if (modalWrapper?.current) {
        focusTrap(modalWrapper.current);
      }
    }
    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, [handleEscape, isModalOpen]);

  // Modal animations
  const modalContentVariants = {
    open: {
      opacity: 1,
      filter: 'blur(0px)',
    },
    closed: {
      opacity: 0,
      filter: 'blur(6px)',
    },
  };
  const modalBgVariants = {
    open: {
      translateY: '0px',
    },
    closed: {
      translateY: '20px',
    },
  };
  const modalNavVariants = {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  };
  const modalWrapperVariants = {
    open: {
      translateY: '0%',
    },
    closed: {
      translateY: '100%',
    },
  };
  const modalVariants = {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  };

  return (
    <AnimatePresence>
      {isModalOpen && (
        <motion.div
          ref={modalWrapper}
          initial='closed'
          animate={isModalOpen ? 'open' : 'closed'}
          exit='closed'
          variants={modalVariants}
          transition={{ duration: 0.5 }}
          className={`map-modal absolute z-1 flex h-full w-full items-start justify-center ${modalType === 'mobile' ? 'map-modal-mobile' : ''}`}
          tabIndex='-1'
          role='dialog'
          aria-modal='true'
        >
          <motion.div
            initial='closed'
            animate={isModalOpen ? 'open' : 'closed'}
            exit='closed'
            variants={modalType === 'mobile' ? modalWrapperVariants : modalVariants}
            transition={{ duration: 0.5 }}
            className='map-modal-wrapper'
          >
            <button
              type='button'
              className='button-close absolute z-10 flex h-14 w-14 items-center justify-center'
              aria-label='close'
              tabIndex='-1'
              onClick={() => close()}
            >
              <SVG
                cacheRequests
                preProcessor={(code) => code.replace(/stroke=".*?"/g, 'stroke="currentColor"')}
                src={closeIcon}
                className='lg:absolute'
              />
              <span>{strings.actions?.close || 'Close'}</span>
            </button>
            {modalType === 'mobile' ? (
              <MapSlider
                data={data}
                pageContext={pageContext}
                strings={strings}
                categories={categories}
              />
            ) : (
              <>
                <div className={`map-modal-visual-wrapper mx-auto preview-${currentModalData.value}`}>
                  <motion.div
                    initial='closed'
                    animate={displayModalData ? 'open' : 'closed'}
                    exit='closed'
                    variants={modalContentVariants}
                    transition={{ duration: 0.5 }}
                    className='flex h-full w-full items-center justify-center'
                  >
                    <MapLocationVisual
                      data={currentModalData}
                      pageContext={pageContext}
                    />
                  </motion.div>
                </div>
                <div className='map-modal-content'>
                  <motion.div
                    initial='closed'
                    animate={displayModalData ? 'open' : 'closed'}
                    exit='closed'
                    variants={modalContentVariants}
                    transition={{ duration: 0.5 }}
                    className='flex h-full flex-grow flex-col justify-between'
                  >
                    <MapLocationContent
                      data={currentModalData}
                      strings={strings}
                      categories={categories}
                      pageContext={pageContext}
                    />
                  </motion.div>
                  <nav className='map-modal-nav'>
                    <ul>
                      <li>
                        <button
                          type='button'
                          onClick={() => locationContentHandler(event, data, prevModalData.value, pageContext)}
                        >
                          <span>
                            <StaticImage
                              src='../../../assets/img/icons/arrow.png'
                              alt={strings?.actions?.previous ? strings.actions.previous : 'Previous'}
                              className='icon-arrow'
                              placeholder='blurred'
                              loading='lazy'
                            />
                            {strings?.actions?.previous ? strings.actions.previous : 'Previous'}
                          </span>
                          <motion.span
                            initial='closed'
                            animate={displayModalData ? 'open' : 'closed'}
                            exit='closed'
                            variants={modalNavVariants}
                            transition={{ duration: 0.5 }}
                          >
                            {prevModalData?.title}
                          </motion.span>
                        </button>
                      </li>
                      <li>
                        <button
                          type='button'
                          onClick={() => locationContentHandler(event, data, nextModalData.value, pageContext)}
                        >
                          <span>
                            {strings?.actions?.next ? strings.actions.next : 'Next'}
                            <StaticImage
                              src='../../../assets/img/icons/arrow.png'
                              alt={strings?.actions?.next ? strings.actions.next : 'Next'}
                              className='icon-arrow'
                              placeholder='blurred'
                              loading='lazy'
                            />
                          </span>
                          <motion.span
                            initial='closed'
                            animate={displayModalData ? 'open' : 'closed'}
                            exit='closed'
                            variants={modalNavVariants}
                            transition={{ duration: 0.3 }}
                          >
                            {nextModalData?.title}
                          </motion.span>
                        </button>
                      </li>
                    </ul>
                  </nav>
                  <motion.div
                    initial='closed'
                    animate={isModalOpen ? 'open' : 'closed'}
                    exit='closed'
                    variants={modalBgVariants}
                    transition={{ duration: 0.5 }}
                    className='map-modal-background gatsby-absolute'
                  >
                    <StaticImage
                      src='../../../assets/img/map/ui/map-modal-texture.jpg'
                      alt=''
                      placeholder='blurred'
                      loading='lazy'
                      className=''
                      role='presentation'
                    />
                  </motion.div>
                </div>
              </>
            )}
          </motion.div>
          <div
            className='map-modal-backdrop absolute left-0 top-0 z-under h-full w-full'
            onClick={() => close()}
            role='none'
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default MapModal;
