import React, { useContext, useState, useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

import { localizePath } from '@base/utils/helpers/localization/localize';
import Button from '@base/parts/button';

import SoundPlayer from '@elements/interactive-elements/sound-player';
import { GateContext } from '@/utils/contexts/gate-context';
import { InteractiveContext } from '@/utils/contexts/interactive-context';
import { DeskContext } from '@/utils/contexts/desk-context';
import { soundEffects } from '@/utils/helpers/sound-effects';
import HintGiver from './hint-giver';

function NavDesk({ data, pageContext }) {
  const itemsList = useRef(null);
  const [listOpen, setListOpen] = useState(false);
  const { soundActive } = useContext(InteractiveContext);
  const [isSoundActive, setSoundActive] = soundActive;
  const { modalOpen, modal, items, itemList } = useContext(DeskContext);
  const [isModalOpen, setModalOpen] = modalOpen;
  const [modalData, setModalData] = modal;
  const [foundItemList, setFoundItemList] = itemList;
  const [itemsToFind, setItemsToFind] = items;
  const strings = pageContext?.globalData?.strings?.edges?.[0]?.node;

  const { mobileState } = useContext(GateContext);
  const [isMobile, setIsMobile] = mobileState;

  const toggleItemList = () => {
    setListOpen(!listOpen);
  };

  useEffect(() => {
    if (!isMobile) {
      setListOpen(true);
    } else {
      setListOpen(false);
    }
  }, [isMobile]);

  // Display Item Modal Box
  const displayItemInformation = (e) => {
    const item = e.target?.attributes?.value?.value;
    if (item) {
      // Open Modal box
      window?.scrollTo({ top: 0, behavior: 'smooth' });
      const itemData = data.hiddenItems.find((x) => x.value === item);
      setModalData(itemData);
      setModalOpen(true);

      // Play Sound Effect
      if (isSoundActive) {
        soundEffects(itemData?.level);
      }
    }
  };

  return (
    <div className='interactive-page-nav pointer-events-none absolute bottom-0 z-[2] flex w-full items-end justify-between pb-8 pr-6 lg:px-12 lg:pb-12'>
      <div
        className={`hidden-elements-list-wrapper pointer-events-auto ${listOpen ? 'open' : ''}`}
      >
        <div className='absolute bottom-full ml-6 transition-all duration-300 md:ml-5'>
          <HintGiver data={data.hiddenItems} strings={strings?.labels} />
          <SoundPlayer pageContext={pageContext} strings={strings?.labels} />
        </div>
        <div className='relative flex h-20 items-end xl:h-16'>
          <button
            type='button'
            className='hidden-elements-list-button'
            onClick={() => toggleItemList()}
          >
            <span className='sr-only'>
              {listOpen
                ? strings?.actions?.close
                  ? strings?.actions?.close
                  : 'Close'
                : strings?.actions?.open
                  ? strings?.actions?.open
                  : 'Open'}
            </span>
            <StaticImage
              src='../../../assets/img/icons/arrow.png'
              alt=''
              className='w-6 lg:w-5'
              placeholder='blurred'
              loading='lazy'
            />
          </button>
          <motion.h4
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className={`reversed absolute bottom-0 ${pageContext.locale === 'ja' && 'title-ja'} ${pageContext.locale === 'de' && 'title-de'}`}>
            <span>
              {pageContext.locale === 'ja' ? (
                <>
                  <b className='font-normal'>{data.nav?.itemsTitle}</b>
                  {foundItemList?.length ? foundItemList?.length : '0'}
                  /
                  {itemsToFind || '5'}
                </>
              ) : (
                <>
                  {foundItemList?.length ? foundItemList?.length : '0'}
                  /
                  {itemsToFind || '5'}
                  <b className='font-normal'>
                    {' '}
                    {data.nav?.itemsTitle}
                  </b>
                </>
              )}
            </span>
          </motion.h4>
        </div>
        <div
          ref={itemsList}
          className='hidden-elements-list overflow-hidden bg-secondary lg:bg-black'
        >
          <ul
            className='px-6 pb-6 pt-32 font-body text-[1.2rem] text-white md:pt-14 md:text-xs xxl:text-sm xxxl:pl-16'
            aria-expanded={listOpen}
          >
            {data.hiddenItems?.map((item, i) => (
              <li
                key={`hidden-elements-list-${i}`}
                className={
                  foundItemList?.find((listItem) => listItem === item.value)
                    ? 'checked'
                    : ''
                }
              >
                {foundItemList?.find((listItem) => listItem === item.value) ? (
                  <a
                    onClick={displayItemInformation}
                    value={item.value}
                    className='cursor-pointer text-white transition-all duration-300 hover:text-green'
                    tabIndex={listOpen ? 0 : -1}
                  >
                    {item.title}
                  </a>
                ) : (
                  item.title
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className='flex w-full items-end justify-end lg:w-auto'>
        {/* {data.nav?.backBtn && (
          <Button
            pageContext={pageContext}
            link={localizePath({ ...pageContext, slug: '' })}
            trigger="internalLink"
            className="button-secondary pointer-events-auto"
            eventAction="the_website"
            eventPage="interactive_desk"
            eventSection="home interactive experience"
          >
            <span>
              {data.nav?.backBtn}
              <StaticImage
                src="../../../assets/img/icons/arrow.png"
                alt=""
                className="icon-arrow w-[1.2rem] lg:w-6 h-[1.2rem] lg:h-6 rotate-90 ml-2"
                placeholder="blurred"
                loading="lazy"
              />
            </span>
          </Button>
        )} */}
      </div>
    </div>
  );
}

export default NavDesk;
