import React from 'react';

import Image from '@base/parts/image';

function Logo({ className, globalData }) {
  const logo = globalData.brandData.alternateLogo;

  return (
    <div className={`logo-wrapper flex flex-col justify-center ${className}`}>
      <Image
        media={logo}
        className='w-[30rem] lg:w-[60rem] h-auto'
      />
    </div>
  );
}

export default Logo;
