import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';

import VideoPlayer from '@base/elements/video-player';
import Image from '@base/parts/image';

import playBtn from '@img/icons/play-btn.svg';

function MapLocationVisual({ data, pageContext }) {
  const [videoFile, setVideoFile] = useState(null);
  let eventAction = '';
  if (data.value === 'clue-calhern') {
    eventAction = 'audio_1_video_play';
  }
  if (data.value === 'clue-clay') {
    eventAction = 'audio_2_video_play';
  }
  if (data.value === 'murder-mill') {
    eventAction = 'murder_mill_video_play';
  }

  useEffect(() => {
    setVideoFile(data.file);
  }, [data.file]);

  return (
    <>
      {data.image && !data.file && (
        <div className='map-modal-image'>
          <Image
            media={data.image}
            alt={data.title}
            className='gatsby-absolute top-0 h-full'
          />
        </div>
      )}
      {data.file && videoFile === data.file && (
        <div className='map-modal-video'>
          <VideoPlayer
            pageContext={pageContext}
            className='video-embed h-full w-full'
            player='youtube'
            poster={data.image.publicURL}
            loop
            lazy
            disableRatings
            source={data.file}
            ratingTimeBuffer={null}
            eventAction={eventAction}
            eventPage='explore_cedar_hills'
          >
            <SVG
              cacheRequests
              preProcessor={(code) => code.replace(/stroke=".*?"/g, 'stroke="currentColor"')}
              src={playBtn}
              className='play-icon'
            />
          </VideoPlayer>
        </div>
      )}
    </>
  );
}

export default MapLocationVisual;
