const triggerPageView = (page, pageContext) => {
  if (!process.env.GATSBY_DATALAYER) {
    return '';
  }

  // Don't track error pages
  if (pageContext.postType === 'error') {
    return '';
  }

  const gameTitle = pageContext?.dataLayer?.game
    ? pageContext.dataLayer.game
    : 'dead by daylight'; // will fallback to dead by daylight by default
  const language = pageContext.locale;
  const pageTitle = pageContext.defaultLocaleTitle;

  // GA4 Tracking
  if (
    pageContext?.dataLayer?.config === 'GA4'
    && typeof window !== 'undefined'
  ) {
    window.dataLayer = window.dataLayer || [];
    const dataObject = {
      language,
      game: gameTitle,
      page: page || pageTitle,
    };

    if (process.env.GATSBY_GTM_ID && process.env.GATSBY_ENV !== 'dev') {
      dataLayer.push(dataObject);
    } else {
      console.log(dataObject);
    }
  }
};

export default triggerPageView;
