import React, { useContext } from 'react';

import { GarageContext } from '@/utils/contexts/garage-context';

function QteDevControl() {
  const { directives, progression, steps, display, result } = useContext(GarageContext);
  const [directiveActive, setDirectiveActive] = directives;
  const [stepProgression, setStepProgression] = progression;
  const [currentStep, setCurrentStep] = steps;
  const [qteDisplay, setQteDisplay] = display;
  const [qteResult, setQteResult] = result;

  // Trigger the QTE
  const displayQTEdirective = (step) => {
    setDirectiveActive(step);
    setQteDisplay(0);
    setQteResult(null);
    setCurrentStep(step);
    setStepProgression(0);
  };

  const showHide = () => {
    document?.getElementById('garageDevTool')?.classList.toggle('hidden');
  };

  return (
    <div className='absolute right-12 top-12 z-god flex flex-col space-y-6'>
      <p className='bg-black text-center font-body text-white p-2'>
        Dev: Steps Selector
        <button onClick={() => showHide()} type='button' className='border-solid border-white border cursor-pointer ml-2 text-white'>
          +
        </button>
      </p>
      <div id='garageDevTool' className='flex flex-col space-y-6 hidden'>
        <button
          type='button'
          className={`btn button button-secondary relative z-10 inline-flex w-full items-center justify-center md:w-auto ${directiveActive === 0 ? 'disabled' : ''}`}
          onClick={() => displayQTEdirective(0)}
        >
          <span>Intro</span>
        </button>
        <button
          type='button'
          className={`btn button button-secondary relative z-10 inline-flex w-full items-center justify-center md:w-auto ${directiveActive === 1 ? 'disabled' : ''}`}
          onClick={() => displayQTEdirective(1)}
        >
          <span>Start Step 1</span>
        </button>
        <button
          type='button'
          className={`btn button button-secondary relative z-10 inline-flex w-full items-center justify-center md:w-auto ${directiveActive === 2 ? 'disabled' : ''}`}
          onClick={() => displayQTEdirective(2)}
        >
          <span>Start Step 2</span>
        </button>
        <button
          type='button'
          className={`btn button button-secondary relative z-10 inline-flex w-full items-center justify-center md:w-auto ${directiveActive === 3 ? 'disabled' : ''}`}
          onClick={() => displayQTEdirective(3)}
        >
          <span>Start Step 3</span>
        </button>
        <button
          type='button'
          className={`btn button button-secondary relative z-10 inline-flex w-full items-center justify-center md:w-auto ${directiveActive === 4 ? 'disabled' : ''}`}
          onClick={() => displayQTEdirective(4)}
        >
          <span>Endgame</span>
        </button>
      </div>

    </div>
  );
}

export default QteDevControl;
