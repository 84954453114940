/* eslint-disable react-hooks/rules-of-hooks */
import { useContext, useCallback } from 'react';

import useLocalStorage from '@base/utils/hooks/useLocalStorage';
import triggerEventClick from '@base/utils/helpers/tracking/trigger-event-click';

import { MapContext } from '@/utils/contexts/map-context';

export const centerMap = (target) => {
  const wrapper = document.querySelector('.map-wrapper');
  const scene = document.querySelector('.map-section');

  if (wrapper && scene) {
    const wrapperRect = wrapper.getBoundingClientRect();
    const wrapperWidth = wrapperRect.width;
    const wrapperHeight = wrapperRect.height;

    const sceneRect = scene.getBoundingClientRect();
    const sceneWidth = sceneRect.width;
    const sceneHeight = sceneRect.height;

    // Find Map Center
    const wrapperOffsetX = (wrapperWidth - sceneWidth) / 2;
    const wrapperOffsetY = (wrapperHeight - sceneHeight) / 2;

    // Restore scaled map pins
    const mapElements = document.querySelectorAll('.map-element');
    mapElements.forEach((mapElement) => {
      const element = mapElement;
      element.style.transition = 'all 0.2s';
      element.style.transform = 'scale(1)';
      setTimeout(() => {
        element.style.transition = 'none';
      }, 200);
    });

    if (target) {
      // Find Button location
      const btnOffsetLeft = target.offsetLeft + (target.clientWidth / 2);
      const btnOffsetTop = target.offsetTop + (target.clientHeight / 2);

      // Center Button in map view
      const xPosition = btnOffsetLeft - (sceneWidth / 2);
      const yPosition = btnOffsetTop - (sceneHeight / 4);

      wrapper.style.transition = 'all 0.2s';
      wrapper.style.transform = `scale(1) translate(-${xPosition}px, -${yPosition}px)`;
    } else {
      wrapper.style.transition = 'all 0.2s';
      wrapper.style.transform = `scale(1) translate(-${wrapperOffsetX}px, -${wrapperOffsetY}px)`;
      setTimeout(() => {
        wrapper.style.transition = 'none';
      }, 200);
    }
  }
};

const getLocationContent = () => {
  const { modalOpen, type, modal, prevModal, nextModal, elementsList } = useContext(MapContext);
  const [isModalOpen, setModalOpen] = modalOpen;
  const [modalType, setModalType] = type;
  const [modalData, setModalData] = modal;
  const [prevModalData, setPrevModalData] = prevModal;
  const [nextModalData, setNextModalData] = nextModal;
  const [clickedElementsList, setClickedElementsList] = elementsList;

  const locationContentHandler = useCallback(
    (event, data, element, pageContext, media, elements) => {
      if (data && media !== 'mobile') {
        // Get Current element data
        const elementIndex = data.map((x) => x.value).indexOf(element);

        // Get Previous and Next elements data (For navigation)
        let previousElement = elementIndex - 1;
        if (previousElement < 0) {
          previousElement = data.length - 1;
        }
        const previousElementData = data[previousElement];
        setTimeout(() => {
          setPrevModalData(previousElementData);
        }, 500);

        let nextElement = elementIndex + 1;
        if (nextElement > data.length - 1) {
          nextElement = 0;
        }
        const nextElementData = data[nextElement];
        setTimeout(() => {
          setNextModalData(nextElementData);
        }, 500);
      }

      if (element) {
        // Save element to clicked list
        if (
          !clickedElementsList.find((listElement) => listElement === element)
        ) {
          setClickedElementsList([...clickedElementsList, element]);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useLocalStorage(
            'set',
            'map-elements-clicked',
            JSON.stringify([...clickedElementsList, element]),
          );
        }

        // Open Modal box
        window?.scrollTo({ top: 0, behavior: 'smooth' });
        setModalType(media);
        setModalData(element);
        setModalOpen(true);

        // Set dataLayer event depending of element clicked
        let eventAction = '';
        if (element === 'garage-hangout') {
          eventAction = 'item_1_garage_hangout';
        }
        if (element === 'cedar-steel') {
          eventAction = 'item_2_frank_stone_desk';
        }
        if (element === 'calhern-pharmacy') {
          eventAction = 'item_3_pharmacy';
        }
        if (element === 'cedar-steel-gates') {
          eventAction = 'item_4_steel_mill_entrance';
        }
        if (element === 'cedar-forest') {
          eventAction = 'item_5_cedar_forest';
        }
        if (element === 'clue-calhern') {
          eventAction = 'item_6_audio_1';
        }
        if (element === 'clue-clay') {
          eventAction = 'item_7_audio_2';
        }
        if (element === 'murder-mill') {
          eventAction = 'item_8_theater';
        }

        // Send tracking data to the dataLayer
        const dataLayer = {
          pageContext,
          event: eventAction,
          page: 'explore_cedar_hills',
        };
        triggerEventClick(dataLayer);
      }

      // Center the map focus on the clicked element
      if (media === 'mobile') {
        centerMap(event?.target);
      }
    },
  );
  return locationContentHandler;
};
export default getLocationContent;
