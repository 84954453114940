import React, { createContext, useState } from 'react';
import useLocalStorage from '@base/utils/hooks/useLocalStorage';

const defaultState = {
  endGameActive: false,
  isSoundActive: false,
  centeredPoint: null,
};
export const InteractiveContext = createContext(defaultState);

function InteractiveContextProvider(props) {
  const [endGameActive, setEndGameActive] = useState(false);
  const [centeredPoint, setCenteredPoint] = useState(null);

  // Sound preferences stored in localStorage
  const soundPreference = useLocalStorage('get', 'sound-enabled');
  const soundEnabled = soundPreference && soundPreference !== null
    ? JSON.parse(soundPreference)
    : false;
  const [isSoundActive, setSoundActive] = useState(soundEnabled);

  return (
    <InteractiveContext.Provider
      value={{
        endGame: [endGameActive, setEndGameActive],
        soundActive: [isSoundActive, setSoundActive],
        mapCenteredPoint: [centeredPoint, setCenteredPoint],
      }}
    >
      {props.children}
    </InteractiveContext.Provider>
  );
}

export default InteractiveContextProvider;
