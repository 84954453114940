import React, { useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

import { InteractiveContext } from '@/utils/contexts/interactive-context';
import { MapContext } from '@/utils/contexts/map-context';
import { centerMap } from '@/utils/helpers/get-location-content';
import MapLocationContent from './location-content';
import MapLocationVisual from './location-visual';

function MapSlider({ data, pageContext, strings, categories }) {
  const { mapCenteredPoint } = useContext(InteractiveContext);
  const [centeredPoint, setCenteredPoint] = mapCenteredPoint;
  const { modal } = useContext(MapContext);
  const [modalData, setModalData] = modal;
  const elementIndex = data.map((x) => x.value).indexOf(modalData);
  const filteredData = data.filter((x) => x.category !== 'disabled');

  // Center current element in viewport
  const slideChange = (index) => {
    const element = filteredData[index]?.value;
    if (element) {
      setCenteredPoint(element);
      const mapElement = document?.querySelector(`.map-elements-wrapper button[value="${element}"]`);
      if (mapElement) {
        centerMap(mapElement);
      }
    }
  };

  return (
    <Swiper
      className='map-modal-slider'
      slidesPerView='1.3'
      initialSlide={elementIndex}
      effect='coverflow'
      grabCursor
      spaceBetween={10}
      centeredSlides
      loop
      coverflowEffect={{
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 1,
        slideShadows: false,
      }}
      modules={[EffectCoverflow]}
      onSlideChange={(swiper) => slideChange(swiper.realIndex)}
    >
      {filteredData.map((element, i) => (
        <SwiperSlide key={`element-${i}`}>
          <div className='map-modal-visual-wrapper'>
            <MapLocationVisual data={element} pageContext={pageContext} />
          </div>
          <div className='map-modal-content'>
            <MapLocationContent
              data={element}
              strings={strings}
              categories={categories}
              pageContext={pageContext}
            />
            <div className='map-modal-background gatsby-absolute'>
              <StaticImage
                src='../../../assets/img/map/ui/map-modal-texture.jpg'
                alt=''
                placeholder='blurred'
                loading='lazy'
                className=''
                role='presentation'
              />
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default MapSlider;
