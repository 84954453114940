import React, { useRef } from 'react';

import garageBackground from '@img/garage/garage-background.jpg';
import GarageContextProvider from '@/utils/contexts/garage-context';
import NavGarage from './nav';

import QteControl from './control';
import LockBox from './lockbox';

function GarageScene({ data, pageContext, globalData }) {
  const garageWrapper = useRef(null);

  return (
    <section className='interactive-section garage-container'>
      <GarageContextProvider>
        <div
          ref={garageWrapper}
          className={`garage garage-${pageContext.locale} flex flex-col justify-between`}
        >
          <QteControl
            pageContext={pageContext}
            data={data}
            globalData={globalData}
          />
          <div className='garage-scene'>
            <img
              src={garageBackground}
              className='garage-image pointer-events-none'
              alt='Garage Hangout Background'
            />
            <div className='garage-light'>
              <div className='garage-light-bulb' />
            </div>
            <LockBox />
          </div>
        </div>
        <NavGarage pageContext={pageContext} data={data.pageData} />
      </GarageContextProvider>
    </section>
  );
}
export default GarageScene;
