/*
  INTERACTIVE PAGE TEMPLATE
  A template to display fully editable pages
*/

import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import PostContextProvider from '@base/utils/contexts/posts-context';
import PageLayout from '@base/layout/page-layout';

import { getGeneralData } from '@api/general-data';
import { getModalBuy } from '@elements/modal-buy';
import FooterSmall from '@elements/footer-small';
import DeskScene from '@elements/interactive-desk/scene';
import GarageScene from '@elements/interactive-garage/scene';
import MapScene from '@elements/interactive-map/scene';
import triggerPageView from '@/utils/helpers/trigger-page-view';
import urlParameters from '@/utils/helpers/url-parameters';
import InteractiveContextProvider from '@/utils/contexts/interactive-context';

function DynamicInteractivePage({ data, pageContext }) {
  const generalData = getGeneralData(pageContext, data);
  const minifiedPageContext = generalData.pageContext;
  const { globalData } = generalData;
  const { modalData } = generalData;
  const { type, title, parentPage, disablePageBreadcrumbs } = data.pageData;

  // Use the project's own "Buy Game" Modal Component
  const modalTitle = minifiedPageContext?.buyModalTitle;
  const modalSubTitle = minifiedPageContext?.buyModalSubTitle;

  const customModalBuy = getModalBuy();

  // Trigger events based on URL parameters
  const urlParametersHandler = urlParameters();
  useEffect(() => {
    urlParametersHandler(minifiedPageContext);
    triggerPageView(data.pageData.pageDataId, pageContext);
  }, []);

  return (
    <PageLayout
      pageContext={minifiedPageContext}
      modalData={modalData}
      breadcrumbs={{
        enableGlobalBreadcrumbs: true,
        disablePageBreadcrumbs,
        postTitle: title,
        postParent: [parentPage],
        strings: { back: globalData.stringsActions.back, home: 'Home' },
      }}
      modalBuyComponent={() => customModalBuy(pageContext, modalData, modalTitle, modalSubTitle)}
    >
      <PostContextProvider>
        <InteractiveContextProvider>
          {type === 'desk' && (
            <DeskScene data={data} pageContext={pageContext} />
          )}
          {type === 'garage' && (
            <GarageScene
              data={data}
              pageContext={pageContext}
              globalData={globalData}
            />
          )}
          {type === 'map' && (
            <MapScene
              data={data}
              pageContext={pageContext}
              globalData={globalData}
            />
          )}
          <FooterSmall
            pageContext={pageContext}
            globalData={globalData}
            className='relative'
          />
        </InteractiveContextProvider>
      </PostContextProvider>
    </PageLayout>
  );
}
export default DynamicInteractivePage;

export const query = graphql`
  query DynamicInteractivePageQuery($id: String!) {
    pageData: interactiveJson(id: { eq: $id }) {
      dataId
      pageDataId
      title
      slug
      locale
      type
      disablePageBreadcrumbs
      disableNewsletterSignup
      disableBuyGameSection
      disableAgeGate
      themeModifier {
        hideMainNav
        removeFooterSocial
        removeFooterStoreLinks
        storeCTALabel
        storeCTALink
        customStylingClass
        disableFooter
      }
      metadata {
        metaTitle
        metaDescription
        canonicalURL
        disablePageIndexing
        shareImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
          publicURL
        }
      }
      nav {
        backBtn
        itemsTitle
        clickDragDirective
        zoomDirective
      }
      hiddenItems {
        value
        title
        description
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
          publicURL
        }
        eventAction
        level
      }
      endGame {
        title
        title2
        text
        subTitle
        subTitle2
      }
      garageScene {
        title
        subTitle
        progressTitle
        progressTitle2
        qteDescription1
        qteDescription2
        qteDescription3
        endgameTitle
        endgameSubtitle
        endgameCtaText
        endgameCtaLink
      }
      mapElements {
        value
        title
        altTitle
        url
        description
        imageDescription {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
          publicURL
        }
        category
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
          publicURL
        }
        file
        eventAction
        eventSection
      }
      mapCategories {
        value
        title
      }
      localizations {
        dataId
        locale
        slug
      }
    }
  }
`;
export { Head } from '@base/layout/head';
