import React, { useState, useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { AnimatePresence, motion } from 'framer-motion';

import triggerEventClick from '@base/utils/helpers/tracking/trigger-event-click';

import { GarageContext } from '@/utils/contexts/garage-context';
import QteButtonBurst from './qte/qte-button-burst';
import QteTimedAction from './qte/qte-timed-action';
import ProgressBar from './progress-bar';
import QteGuide from './qte/guide';
import QteDevControl from './dev-control';
import Endgame from './endgame';

function QteControl({ data, pageContext, globalData }) {
  const { directives, progression, steps, display, result } = useContext(GarageContext);
  const [directiveActive, setDirectiveActive] = directives;
  const [stepProgression, setStepProgression] = progression;
  const [currentStep, setCurrentStep] = steps;
  const [qteDisplay, setQteDisplay] = display;
  const [qteResult, setQteResult] = result;

  const [qteRunning, setQteRunning] = useState(false);
  const garageData = data?.pageData?.garageScene;

  const startQte = () => {
    setQteRunning(true);
    setDirectiveActive(1);
    setQteDisplay(0);
    setQteResult(null);
    setCurrentStep(1);
    setStepProgression(0);

    // Send tracking data to the dataLayer
    const dataLayer = {
      pageContext,
      event: 'unlock',
      page: 'garage_hangout',
    };
    triggerEventClick(dataLayer);
  };

  const startHover = (event) => {
    if (event === 'enter') {
      document?.querySelector('.lockbox')?.classList.add('hover');
    } else if (event === 'leave') {
      document?.querySelector('.lockbox')?.classList.remove('hover');
    }
  };

  return (
    <>
      <AnimatePresence>
        {(!qteRunning || directiveActive === 0) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.5, delay: 1 },
            }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className='qte-intro relative z-1 mt-64 flex flex-col items-center'
          >
            <h1 className='multi-line mark h3 centered mb-12 text-center'>
              {garageData?.title && (
                <span className='pointer-events-auto'>
                  <mark>{garageData?.title}</mark>
                </span>
              )}
              {garageData?.subTitle && (
                <span className='pointer-events-auto'>
                  <mark>{garageData?.subTitle}</mark>
                </span>
              )}
            </h1>
            <button
              type='button'
              className='btn button larger narrow relative z-10 inline-flex w-full items-center justify-center md:w-auto'
              onClick={() => startQte()}
              onMouseEnter={() => startHover('enter')}
              onMouseLeave={() => startHover('leave')}
            >
              <span>
                {globalData.stringsAll?.cta?.unlock
                  ? globalData.stringsAll.cta.unlock
                  : 'Unlock'}
                <StaticImage
                  src='../../../assets/img/icons/arrow.png'
                  alt=''
                  className='icon-arrow ml-3 hidden md:flex'
                  placeholder='blurred'
                  loading='lazy'
                />
              </span>
            </button>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {qteRunning && directiveActive !== 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className='qte-modal z-1'
          >
            {process.env.GATSBY_ENV === 'dev' && <QteDevControl />}
            <ProgressBar
              title={garageData.progressTitle}
              subTitle={garageData.progressTitle2}
              locale={pageContext.locale}
            />
            <div className='relative flex h-auto w-full flex-col items-center justify-center'>
              {(currentStep === 1 || currentStep === 2) && (
                <QteTimedAction strings={globalData.stringsActions} />
              )}
              {currentStep === 3 && (
                <QteButtonBurst strings={globalData.stringsActions} />
              )}
              <QteGuide
                data={{
                  descriptionStep1: garageData.qteDescription1,
                  descriptionStep2: garageData.qteDescription2,
                  descriptionStep3: garageData.qteDescription3,
                }}
                pageContext={pageContext}
                strings={globalData.stringsAll.cta}
              />
            </div>
            {currentStep === 4 && (
              <Endgame
                pageContext={pageContext}
                data={{
                  title: garageData.endgameTitle,
                  subTitle: garageData.endgameSubtitle,
                  ctaText: garageData.endgameCtaText,
                  ctaLink: garageData.endgameCtaLink,
                }}
                strings={{
                  actions: globalData.stringsAll.actions,
                  cta: globalData.stringsAll.cta,
                }}
              />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default QteControl;
