import React, { useEffect, useState } from 'react'
import SVG from 'react-inlinesvg'

// Hook to import the mask of a given item
const useMask = (itemValue) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [image, setImage] = useState(null)

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await import(`../../../assets/img/interactive-desk/masks/${itemValue}-mask.svg`)
        setImage(response.default)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    fetchImage()
  }, [itemValue])

  return {
    loading,
    error,
    image,
  }
}

// Display Item Mask SVG
const ItemMask = ({ value }) => {
  const { loading, error, image } = useMask(value)
  if (error) {
    return ''
  }
  return (
    !loading && (
      <SVG
        onError={error => console.log(error.message)}
        cacheRequests={true}
        preProcessor={code =>
          code.replace(/stroke=".*?"/g, 'stroke="currentColor"')
        }
        src={image}
        className="absolute w-0 h-0"
      />
    )
  )

}

export default ItemMask
