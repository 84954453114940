import React, { createContext, useState } from 'react';

const defaultState = {
  directiveActive: '0',
  currentStep: 0,
  stepProgression: null,
  qteDisplay: null,
  qteResult: null,
};
export const GarageContext = createContext(defaultState);

function GarageContextProvider(props) {
  const [directiveActive, setDirectiveActive] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepProgression, setStepProgression] = useState(null);
  const [qteDisplay, setQteDisplay] = useState(null);
  const [qteResult, setQteResult] = useState(null);

  return (
    <GarageContext.Provider
      value={{
        directives: [directiveActive, setDirectiveActive],
        steps: [currentStep, setCurrentStep],
        progression: [stepProgression, setStepProgression],
        display: [qteDisplay, setQteDisplay],
        result: [qteResult, setQteResult],
      }}
    >
      {props.children}
    </GarageContext.Provider>
  );
}

export default GarageContextProvider;
