import { useContext, useCallback, useEffect } from 'react';

import { InteractiveContext } from '@/utils/contexts/interactive-context';
import { GarageContext } from '@/utils/contexts/garage-context';
import { soundEffects } from '@/utils/helpers/sound-effects';

const useQteProgression = () => {
  const { soundActive } = useContext(InteractiveContext);
  const [isSoundActive, setSoundActive] = soundActive;
  const { directives, progression, steps, display, result } = useContext(GarageContext);
  const [stepProgression, setStepProgression] = progression;
  const [qteResult, setQteResult] = result;
  const [directiveActive, setDirectiveActive] = directives;
  const [currentStep, setCurrentStep] = steps;
  const [qteDisplay, setQteDisplay] = display;

  const progressionHandler = useCallback((type, increment) => {
    let newProgression = stepProgression;

    if (type === 'success') {
      setQteResult(true);
      if (stepProgression < 100) {
        newProgression = stepProgression + increment;
        setStepProgression(newProgression);
      }
    } else if (type === 'fail') {
      setQteResult(false);
      if (stepProgression > 0) {
        newProgression = stepProgression - increment;
        setStepProgression(newProgression);
      } else {
        setStepProgression(0);
      }
    }

    // Play Sound Effect
    if (isSoundActive) {
      soundEffects(type);
    }

    return newProgression;
  });

  // Step Success, proceed to next step
  useEffect(() => {
    const nextStep = qteDisplay + 1;
    if (stepProgression >= 100 && qteDisplay < 4) {
      setTimeout(() => {
        setCurrentStep(nextStep);
        setTimeout(() => {
          setDirectiveActive(nextStep);
        }, 1000);
      }, 1000);
    }
  }, [stepProgression]);

  return progressionHandler;
};
export default useQteProgression;
