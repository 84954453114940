import React, { createContext, useState } from 'react';
import useLocalStorage from '@base/utils/hooks/useLocalStorage';

const defaultState = {
  isModalOpen: false,
  modalType: null,
  modalData: null,
  allMapData: null,
  prevModalData: null,
  nextModalData: null,
  mapElements: null,
  clickedElementsList: [],
};
export const MapContext = createContext(defaultState);

function MapContextProvider(props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [allMapData, setAllMapData] = useState(null);
  const [prevModalData, setPrevModalData] = useState(null);
  const [nextModalData, setNextModalData] = useState(null);

  // Clicked Elements stored in localStorage
  let clickedElements = [];
  const storedItems = useLocalStorage('get', 'map-elements-clicked');

  if (storedItems) {
    clickedElements = JSON.parse(storedItems);
  }
  const [clickedElementsList, setClickedElementsList] = useState(clickedElements);

  return (
    <MapContext.Provider
      value={{
        modalOpen: [isModalOpen, setModalOpen],
        type: [modalType, setModalType],
        modal: [modalData, setModalData],
        mapData: [allMapData, setAllMapData],
        prevModal: [prevModalData, setPrevModalData],
        nextModal: [nextModalData, setNextModalData],
        elementsList: [clickedElementsList, setClickedElementsList],
      }}
    >
      {props.children}
    </MapContext.Provider>
  );
}

export default MapContextProvider;
