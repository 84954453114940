/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from 'react';

import useModalBox from '@base/utils/hooks/useModalBox';

const urlParameters = () => {
  const [modal, openModalBox] = useModalBox();

  const urlParametersHandler = useCallback((pageContext) => {
    const params = new URLSearchParams(location.search);

    // Open Buy Modal Box with an URL parameter
    const modalParams = !!(params?.has('wishlist') || params?.has('buy') || params?.has('pre-order-deluxe'));
    if (modalParams) {
      const title = pageContext?.buyModalTitle;
      const subtitle = pageContext?.buyModalSubTitle;

      const modalData = {
        title,
        subtitle,
        link: 'base-deluxe',
      };
      openModalBox(
        'buyGame',
        'modal-buy relative h-full w-full lg:h-5/6 lg:w-5/6 overflow-hidden',
        modalData,
      );
    }
  });

  return urlParametersHandler;
};

export default urlParameters;
