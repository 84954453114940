import React, { useState, useContext, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SVG from 'react-inlinesvg';

import hintOn from '@img/icons/hint-bulb-on.svg';
import { DeskContext } from '@/utils/contexts/desk-context';

function HintGiver({ data, strings }) {
  const hintBtn = useRef(null);
  const [hintHover, setHintHover] = useState(false);
  const { items, itemList } = useContext(DeskContext);
  const [foundItemList, setFoundItemList] = itemList;
  const [itemsToFind, setItemsToFind] = items;

  const handleHint = () => {
    const foundItems = [];
    const notFoundItems = [];

    if (hintBtn?.current?.classList.contains('temp-disabled')) {
      return;
    }

    // Find remaining items
    data.forEach((item) => {
      if (foundItemList.find((x) => x === item.value)) {
        foundItems.push(item.value);
      } else {
        notFoundItems.push({ value: item.value, level: item.level });
      }
    });

    // Order hints by level (easier items first)
    sortHints(notFoundItems);

    // Temporarly disable button when hint animation is playing
    hintBtn?.current?.classList.add('temp-disabled');

    // Split the scene in 4 quadrants
    const quadrant = (hintedElement) => {
      const scene = document.querySelector('.interactive-desk');
      const { width, height } = scene.getBoundingClientRect();
      const quadrantMinusPlus = { x: [0, width / 2], y: [0, height / 2] };
      const quadrantPlusPlus = {
        x: [width / 2 + 1, width],
        y: [0, height / 2],
      };
      const quadrantMinusMinus = {
        x: [0, width / 2],
        y: [height / 2 + 1, height],
      };
      const quadrantPlusMinus = {
        x: [width / 2 + 1, width],
        y: [height / 2 + 1, height],
      };

      const scale = {
        x: width / scene.offsetWidth,
        y: height / scene.offsetHeight,
      };
      const hintOffsetLeft = hintedElement.offsetLeft * scale.x;
      const hintOffsetTop = hintedElement.offsetTop * scale.y;

      // -,+
      if (
        hintOffsetLeft >= quadrantMinusPlus.x[0]
        && hintOffsetLeft <= quadrantMinusPlus.x[1]
        && hintOffsetTop >= quadrantMinusPlus.y[0]
        && hintOffsetTop <= quadrantMinusPlus.y[1]
      ) {
        // Object is positionned to the left / to the top
        return getQuadrantOffset(scene, 'minusPlus', quadrantMinusPlus, [
          hintOffsetLeft,
          hintOffsetTop,
        ]);
      }

      // +,+
      if (
        hintOffsetLeft >= quadrantPlusPlus.x[0]
        && hintOffsetLeft <= quadrantPlusPlus.x[1]
        && hintOffsetTop >= quadrantPlusPlus.y[0]
        && hintOffsetTop <= quadrantPlusPlus.y[1]
      ) {
        // Object is positionned to the right / to the top
        return getQuadrantOffset(scene, 'plusPlus', quadrantPlusPlus, [
          hintOffsetLeft,
          hintOffsetTop,
        ]);
      }

      // -,-
      if (
        hintOffsetLeft >= quadrantMinusMinus.x[0]
        && hintOffsetLeft <= quadrantMinusMinus.x[1]
        && hintOffsetTop >= quadrantMinusMinus.y[0]
        && hintOffsetTop <= quadrantMinusMinus.y[1]
      ) {
        // Object is positionned to the left / to the bottom
        return getQuadrantOffset(scene, 'minusMinus', quadrantMinusMinus, [
          hintOffsetLeft,
          hintOffsetTop,
        ]);
      }

      // +,-
      /* if (
           hintOffsetLeft >= quadrantPlusMinus.x[0] &&
           hintOffsetLeft <= quadrantPlusMinus.x[1] &&
           hintOffsetTop >= quadrantPlusMinus.y[0] &&
           hintOffsetTop <= quadrantPlusMinus.y[1]) {

           // Object is positionned to the right / to the bottom
           if (scrollPosition.x < quadrantPlusMinus.x[0] || scrollPosition.y < quadrantPlusMinus.y[0]) {
             return 'plusMinusOffset'
           } else {
             return 'plusMinus'
           }
         } */
    };

    // Get Quadrant offset depending of user's position
    const getQuadrantOffset = (scene, userPosition, quadrant, elementPosition) => {
      const { x, y } = scene.getBoundingClientRect()
      const scrollPosition = { x: Math.abs(x), y: Math.abs(y) }


      if (userPosition === 'minusPlus') {
        if ((scrollPosition.x > elementPosition[0]) && (scrollPosition.y > elementPosition[1])) {
          return ['minusPlus', 'minusPlus']
        } if ((scrollPosition.x > elementPosition[0])) {
          return ['minusPlus', 'minusPlusOffset']
        } if ((scrollPosition.y > elementPosition[1])) {
          return ['minusPlusOffset', 'minusPlus']
        } 
          return ['minusPlusOffset', 'minusPlusOffset']
        
      }

      if (userPosition === 'plusPlus') {
        if ((scrollPosition.x < elementPosition[0]) && (scrollPosition.y > elementPosition[1])) {
          return ['plusPlus', 'plusPlus']
        } if (scrollPosition.x < elementPosition[0]) {
          return ['plusPlus', 'plusPlusOffset']
        } if (scrollPosition.y > elementPosition[1]) {
          return ['plusPlusOffset', 'plusPlus']
        } 
          return ['plusPlusOffset', 'plusPlusOffset']
        
      }

      if (userPosition === 'minusMinus') {
        if ((scrollPosition.x > elementPosition[0]) && (scrollPosition.y < elementPosition[1])) {
          return ['minusMinus', 'minusMinus']
        } if (scrollPosition.x > elementPosition[0]) {
          return ['minusMinus', 'minusMinusOffset']
        } if (scrollPosition.y < elementPosition[1]) {
          return ['minusMinusOffset', 'minusMinus']
        } 
          return ['minusMinusOffset', 'minusMinusOffset']
        
      }
    }

    // Display Hint
    if (notFoundItems?.[0]) {
      const hintedElement = document.querySelector(
        `.hidden-element[value=${notFoundItems[0].value}]`,
      );
      const hintedElementState = document.querySelector(
        `.hidden-element-state[value=${notFoundItems[0].value}]`,
      );
      const hintRect = hintedElement.getBoundingClientRect();
      const hintLocation = [hintRect.x, hintRect.y];
      const hintSize = [hintRect.width, hintRect.height];

      // Lookup if hint is in viewport
      const sceneWrapper = document.querySelector(
        '.interactive-desk-container',
      );
      const sceneSize = [sceneWrapper.clientWidth, sceneWrapper.clientHeight];

      // Specify in which quadrant the element is located
      const quadrantPositionning = quadrant(hintedElement);

      // Validate if hint is visible on X axis of viewport
      if (
        hintLocation[0] >= -(hintSize[0] / 2) &&
        hintLocation[0] <= sceneSize[0] - hintSize[0] / 2
      ) {
        document.querySelector('.hint-border-top').classList.add('y-center');
        document.querySelector('.hint-border-bottom').classList.add('y-center');
      } else {
        // Lookup if hint is located in the right part of the scene
        if (hintLocation[0] >= sceneSize[0] - hintSize[0] / 2) {
          document
            .querySelector('.hint-border-right')
            .classList.add('visible', quadrantPositionning[1]);
        }
        // Lookup if hint is located in the left part of the scene
        if (hintLocation[0] <= -(hintSize[0] / 2)) {
          document
            .querySelector('.hint-border-left')
            .classList.add('visible', quadrantPositionning[1]);
        }
      }

      // Validate if hint is visible on Y axis of viewport
      if (
        hintLocation[1] >= -(hintSize[1] / 2) &&
        hintLocation[1] <= sceneSize[1] - hintSize[1] / 2
      ) {
        document.querySelector('.hint-border-left').classList.add('x-center');
        document.querySelector('.hint-border-right').classList.add('x-center');
      } else {
        // Lookup if hint is located in the bottom part of the scene
        if (hintLocation[1] >= sceneSize[1] - hintSize[1] / 2) {
          document
            .querySelector('.hint-border-bottom')
            .classList.add('visible', quadrantPositionning[0]);
        }
        // Lookup if hint is located in the top part of the scene
        if (hintLocation[1] <= -(hintSize[1] / 2)) {
          document
            .querySelector('.hint-border-top')
            .classList.add('visible', quadrantPositionning[0]);
        }
      }

      // Highlight element
      hintedElement.classList.add('hidden-element-hint');
      hintedElementState.classList.add('hidden-element-hint');
      setTimeout(() => {
        if (notFoundItems?.[0]) {
          hintedElement.classList.remove('hidden-element-hint');
          hintedElementState.classList.remove('hidden-element-hint');
        }
        hintBtn?.current?.classList.remove('temp-disabled');
        document.querySelectorAll('.hint-borders div').forEach((border) => {
          border.classList.remove('visible');
          setTimeout(() => {
            border.classList.remove(
              quadrantPositionning[0],
              quadrantPositionning[1],
              'x-center',
              'y-center',
            );
          }, 800);
        });
      }, 3000);
    }
  };

  const sortHints = (hints) => {
    hints.sort((a, b) => a.level.toString().localeCompare(b.level.toString()));
  };

  const handleHintHover = (state) => {
    if (state === 'on') {
      setHintHover(true);
    } else {
      setHintHover(false);
    }
  };

  return (
    <button
      ref={hintBtn}
      type='button'
      className={`hint-giver relative mb-8 flex items-center px-0 transition-all duration-300 ${foundItemList.length === itemsToFind ? 'disabled' : ''}`}
      onClick={() => handleHint()}
      onMouseEnter={() => handleHintHover('on')}
      onMouseLeave={() => handleHintHover('off')}
    >
      <StaticImage
        src='../../../assets/img/icons/hint-bulb.png'
        alt=''
        placeholder='blurred'
        loading='lazy'
        className='mr-3 h-auto w-12 lg:w-14'
      />
      <SVG
        onError={(error) => console.log(error.message)}
        cacheRequests
        preProcessor={(code) => code.replace(/stroke=".*?"/g, 'stroke="currentColor"')}
        src={hintOn}
        className={`gatsby-absolute -top-[0.1rem] left-[0.1rem] h-auto w-9 origin-center overflow-visible text-white transition-all duration-300 lg:-left-[0.1rem] lg:-top-[0.2rem] lg:w-12 ${hintHover ? 'rotate-0 scale-100 opacity-100' : 'rotate-12 scale-50 opacity-0'}`}
      />

      <span className='hidden font-body text-xs font-black uppercase text-white lg:flex'>
        {strings.hint}
      </span>
    </button>
  );
}

export default HintGiver;
