import React, { useRef, useState, useEffect, useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import SVG from 'react-inlinesvg';

import useWindowSize from '@base/utils/hooks/useWindowSize';

import mapBackground from '@img/map/ui/cedar-hills-map-background.jpg';
import mapContent from '@img/map/cedar-hills-map-content.png';
import mapContentMobile from '@img/map/cedar-hills-map-content-mobile.jpg';
import mapBorder from '@img/map/ui/cedar-hills-map-border.svg';
import MapContextProvider from '@/utils/contexts/map-context';
import { InteractiveContext } from '@/utils/contexts/interactive-context';
import PinchZoom from '@/utils/helpers/pinch-zoom';
import NavMap from './nav';
import MapModal from './modal';
import MapMarkers from './markers';

function MapScene({ data, globalData, pageContext }) {
  const { mapCenteredPoint } = useContext(InteractiveContext);
  const [centeredPoint, setCenteredPoint] = mapCenteredPoint;
  const mapWrapper = useRef(null);
  const map = useRef(null);

  // Set Mobile State
  const [isMobile, setIsMobile] = useState(null);
  const windowSize = useWindowSize();
  useEffect(() => {
    if (windowSize.width <= 991) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize]);

  // Pinch Zoom
  const pzRef = useRef(null);
  useEffect(() => {
    const mapElements = document.querySelectorAll('.map-element');
    if (pzRef.current === null) {
      const pzOptions = {
        draggableUnzoomed: true,
        minZoom: 1,
        maxZoom: 2.5,
        tapZoomFactor: 2.5,
        useMouseWheel: false,
        use2d: false,
        onZoomUpdate(object, event) {
          mapElements.forEach((mapElement) => {
            const element = mapElement;
            const scaledWidth = element.getBoundingClientRect().width;
            const defaultWidth = scaledWidth / object.zoomFactor;
            const scaleFactor = defaultWidth / scaledWidth;
            element.style.transform = `scale(${scaleFactor})`;
          });
        },
      };
      pzRef.current = new PinchZoom(mapWrapper.current, pzOptions);
    }

    if (isMobile) {
      pzRef.current.enable();
    } else {
      pzRef.current.disable();
    }
  }, [isMobile]);

  useEffect(() => {
    if (pzRef.current !== null) {
      pzRef.current.reset();
    }
  }, [centeredPoint]);

  return (
    <section className='map-section'>
      <MapContextProvider>
        <NavMap data={data?.pageData?.mapCategories} />
        <StaticImage
          src='../../../assets/img/map/ui/map-compass-rose.png'
          alt='Cedar Hills Map Compass Rose'
          placeholder='blurred'
          loading='lazy'
          className='map-image-compass gatsby-absolute gatsby-hidden-desktop'
        />
        <div ref={mapWrapper} className='map-wrapper'>
          <motion.div
            ref={map}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, delay: 1 }}
            className='map-scene'
          >
            <SVG
              cacheRequests
              preProcessor={(code) => code.replace(/stroke=".*?"/g, 'stroke="currentColor"')}
              src={mapBorder}
              className='map-image-border'
            />
            <div className='map-image-placeholder pointer-events-none mix-blend-multiply'>
              <img src={mapContent} alt='Cedar Hills Map' />
            </div>
            <img
              src={mapBackground}
              alt=''
              role='presentation'
              className='map-image-background gatsby-absolute'
            />
            <div className='map-content absolute h-auto w-auto'>
              <img
                src={mapContent}
                className='map-image gatsby-hidden-mobile pointer-events-none opacity-0'
                alt=''
                role='presentation'
              />
              <img
                src={mapContentMobile}
                className='map-image gatsby-hidden-desktop pointer-events-none opacity-0'
                alt=''
                role='presentation'
              />
              <MapMarkers
                data={data?.pageData?.mapElements}
                pageContext={pageContext}
              />
            </div>
          </motion.div>
        </div>
        <MapModal
          data={data?.pageData?.mapElements}
          pageContext={pageContext}
          strings={{
            actions: globalData.stringsActions,
            cta: globalData.stringsAll.cta,
          }}
          categories={data?.pageData?.mapCategories}
        />
      </MapContextProvider>
    </section>
  );
}

export default MapScene;
