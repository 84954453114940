import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';
import SVG from 'react-inlinesvg';

import Image from '@base/parts/image';
import Heading from '@base/parts/heading';
import Markdown from '@base/parts/markdown';
import Button from '@base/parts/button';

import { useCategoryIcon } from './icon';

function MapLocationContent({ data, pageContext, strings, categories }) {
  const { loading, image } = useCategoryIcon(data.category, 'alt');

  let eventAction = '';
  if (data.value === 'garage-hangout') {
    eventAction = 'popup_start_cta_garage_hangout';
  }
  if (data.value === 'cedar-steel') {
    eventAction = 'popup_start_cta_frank_stone_desk';
  }

  return (
    <AnimatePresence>
      <div className='map-modal-text'>
        {data.imageDescription && (
          <motion.div
            initial={{ rotate: '0deg' }}
            animate={{ rotate: '5deg' }}
            transition={{
              duration: 0.5,
              delay: 0.15,
            }}
            className='map-modal-text-image'
          >
            <Image media={data.imageDescription} alt={data.title} />
          </motion.div>
        )}
        <Heading
          text={data.title}
          tag='h3'
          className={`mb-6 text-s leading-normal md:mb-12 lg:leading-tight ${data.imageDescription ? 'lg:text-md' : 'lg:text-lg'}`}
        />
        <Markdown className={`font-body text-black lg:leading-normal ${data.imageDescription ? 'text-xs' : 'text-s'}`}>
          {data.description}
        </Markdown>
      </div>
      <div className='map-modal-category'>
        {!loading && (
          <span className='map-icon-category'>
            <SVG
              cacheRequests
              preProcessor={(code) => code.replace(/stroke=".*?"/g, 'stroke="currentColor"')}
              src={image}
              className='map-icon'
            />
            {categories?.find((current) => current.value === data.category)?.title}
          </span>
        )}

        {data.url && (
          <Button
            link={data.url}
            trigger='internalLink'
            className='button-secondary narrow'
            pageContext={pageContext}
            eventAction={eventAction}
            eventPage='explore_cedar_hills'
          >
            <span>
              {strings?.cta?.start ? strings.cta.start : 'Start'}
              <StaticImage
                src='../../../assets/img/icons/arrow.png'
                alt=''
                className='icon-arrow ml-3 hidden md:flex'
                placeholder='blurred'
                loading='lazy'
              />
            </span>
          </Button>
        )}
      </div>
    </AnimatePresence>
  );
}

export default MapLocationContent;
