import React, { useContext, useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { AnimatePresence, motion } from 'framer-motion';

import { InteractiveContext } from '@/utils/contexts/interactive-context';
import { GarageContext } from '@/utils/contexts/garage-context';
import { soundEffects } from '@/utils/helpers/sound-effects';

function ProgressBar({ title, subTitle, locale }) {
  const { soundActive } = useContext(InteractiveContext);
  const [isSoundActive, setSoundActive] = soundActive;
  const { progression, directives, steps } = useContext(GarageContext);
  const [stepProgression, setStepProgression] = progression;
  const [directiveActive, setDirectiveActive] = directives;
  const [currentStep, setCurrentStep] = steps;
  const [partsUnlocked, setPartsUnlocked] = useState(0);

  // Move to the next Step when current step progression is completed
  const levelUp = () => {
    if (currentStep === 1 && stepProgression >= 100) {
      setCurrentStep(2);
      setStepProgression(0);
    }
    if (currentStep === 2 && stepProgression >= 100) {
      setCurrentStep(3);
      setStepProgression(0);
    }
    if (currentStep === 3 && stepProgression >= 100) {
      setCurrentStep(4);
      setStepProgression(0);
    }
  };

  useEffect(() => {
    if (stepProgression >= 100) {
      levelUp();
    }
  }, [stepProgression]);

  useEffect(() => {
    // Set the number of parts currently unlocked
    if (currentStep >= 1) {
      setPartsUnlocked(currentStep - 1);
    }

    // Play sound effect when unlocking step
    if (isSoundActive && currentStep >= 2) {
      soundEffects(currentStep + 1);
    }
  }, [currentStep]);

  return (
    <AnimatePresence>
      {currentStep <= 4 && directiveActive <= 3 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className='qte flex w-full flex-col items-center justify-center'
        >
          <h3 className='h3 thick multi-line centered straight mb-8 md:mb-20'>
            {locale === 'ja' ? (
              <>
                {subTitle && (
                  <span className='pointer-events-auto'>{subTitle}</span>
                )}
                {title && (
                  <span className='pointer-events-auto'>{`${partsUnlocked} ${title || '/3'}`}</span>
                )}
              </>
            ) : (
              <>
                {title && (
                  <span className='pointer-events-auto'>{`${partsUnlocked} ${title || ' of 3 parts'}`}</span>
                )}
                {subTitle && (
                  <span className='pointer-events-auto'>{subTitle}</span>
                )}
              </>
            )}

          </h3>
          <div className='qte-progressbar select-none'>
            <div
              className={`qte-progress step1 ${currentStep > 1 ? 'completed' : ''}`}
              style={{
                '--value': `${currentStep === 1
                  ? `${stepProgression}%`
                  : currentStep !== 0
                    ? '100%'
                    : '0%'
                }`,
              }}
            >
              <Step />
            </div>
            <div
              className={`qte-progress step2 ${currentStep > 2 ? 'completed' : ''}`}
              style={{
                '--value': `${currentStep === 2
                  ? `${stepProgression}%`
                  : currentStep === 3 || currentStep === 4
                    ? '100%'
                    : '0%'
                }`,
              }}
            >
              <Step />
            </div>
            <div
              className={`qte-progress step3 ${currentStep > 3 ? 'completed' : ''}`}
              style={{
                '--value': `${currentStep === 3
                  ? `${stepProgression}%`
                  : currentStep === 4
                    ? '100%'
                    : '0%'
                }`,
              }}
            >
              <Step />
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default ProgressBar;

function Step() {
  return (
    <>
      <div className='qte-progress-wrapper'>
        <div className='progress-bar' />
      </div>
      <div className='qte-progress-final'>
        <span />
        <div className='qte-progress-padlock'>
          <StaticImage
            src='../../../assets/img/icons/padlock-shank-icon.png'
            placeholder='blurred'
            loading='lazy'
            className='qte-progress-padlock-shank gatsby-absolute'
          />
          <StaticImage
            src='../../../assets/img/icons/padlock-icon.png'
            placeholder='blurred'
            loading='lazy'
            className='qte-progress-padlock-body gatsby-absolute'
          />
        </div>
      </div>
      <div className='qte-progress-ripple' />
    </>
  );
}
