import React, { createContext, useState } from 'react';
import useLocalStorage from '@base/utils/hooks/useLocalStorage';

const defaultState = {
  isModalOpen: false,
  modalData: null,
  itemsToFind: null,
  foundItemList: [],
  directiveActive: '0',
};
export const DeskContext = createContext(defaultState);

function DeskContextProvider(props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [itemsToFind, setItemsToFind] = useState(null);
  const [directiveActive, setDirectiveActive] = useState('0');

  // Found Items stored in localStorage
  let foundItems = [];
  const storedItems = useLocalStorage('get', 'items-found');

  if (storedItems) {
    foundItems = JSON.parse(storedItems);
  }
  const [foundItemList, setFoundItemList] = useState(foundItems);

  return (
    <DeskContext.Provider
      value={{
        modalOpen: [isModalOpen, setModalOpen],
        modal: [modalData, setModalData],
        items: [itemsToFind, setItemsToFind],
        itemList: [foundItemList, setFoundItemList],
        directives: [directiveActive, setDirectiveActive],
      }}
    >
      {props.children}
    </DeskContext.Provider>
  );
}

export default DeskContextProvider;
