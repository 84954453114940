import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';

export const useCategoryIcon = (itemValue, type) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await import(
          `../../../assets/img/map/icons/map-${itemValue}-icon${type === 'alt' ? '-alt' : ''}.svg`
        );
        setImage(response.default);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchImage();
  }, [itemValue]);

  return {
    loading,
    error,
    image,
  };
};

function CategoryIcon({ value, type, title, categories }) {
  const { loading, error, image } = useCategoryIcon(value, type);
  if (error) {
    return '';
  }

  const category = categories?.find((current) => current.value === value);

  if (title && category) {
    return (
      !loading && (
        <span className='map-icon-category'>
          <SVG
            cacheRequests
            preProcessor={(code) => code.replace(/stroke=".*?"/g, 'stroke="currentColor"')}
            src={image}
            className='map-icon'
          />
          {category.title}
        </span>
      )
    );
  }
  return (
    !loading && (
      <SVG
        cacheRequests
        preProcessor={(code) => code.replace(/stroke=".*?"/g, 'stroke="currentColor"')}
        src={image}
        className='map-icon'
      />
    )
  );
}

export default CategoryIcon;
