import React, { useContext, useState, useEffect, useRef } from 'react';
import SVG from 'react-inlinesvg';

import handle from '@img/garage/qte-action-handle.svg';
import border from '@img/garage/qte-circle-border.svg';
import { GarageContext } from '@/utils/contexts/garage-context';
import useQteProgression from '@/utils/helpers/qte-progression';
import QteButton from './button';

function QteTimedAction({ strings }) {
  const { directives, progression, display, result } = useContext(GarageContext);
  const [directiveActive, setDirectiveActive] = directives;
  const [stepProgression, setStepProgression] = progression;
  const [qteDisplay, setQteDisplay] = display;
  const [qteResult, setQteResult] = result;

  const [qteRun, setQteRun] = useState(false);

  const actionHandle = useRef(null); // Handle: The moving handle that points when the event is stopped
  const actionTarget = useRef(null); // Target: The section that you must stop the handle within
  const actionButton = useRef(null); // Button: The button that you must click to stop the handle
  const requestRef = useRef();
  const previousTimeRef = useRef();

  const [handleRotation, setHandleRotation] = useState(0); // The amount of degrees that the handle is rotated
  const handleSpeedRef = useRef(0.1); // The speed that the handle is moving. Greater the number is, faster the handle is moving

  const targetSuccessWidth = qteDisplay === 2 ? 54 : 79; // 15% of 360deg = 54deg, 22% of 360deg = 79deg
  const [targetRotation, setTargetRotation] = useState(
    Math.random() * (360 - targetSuccessWidth - 120) + 120,
  ); // The amount of degrees that the target is rotated

  const progressionHandler = useQteProgression();

  // Spin the Handle around the circle
  const spinHandle = () => {
    const rightNow = new Date().getTime();
    if (previousTimeRef.current !== undefined) {
      const deltaTime = rightNow - previousTimeRef.current;
      setHandleRotation(
        (prevCount) => Math.round(prevCount + deltaTime * handleSpeedRef.current) % 400,
      );
    }
    previousTimeRef.current = rightNow;
    requestRef.current = requestAnimationFrame(spinHandle);
  };
  useEffect(() => {
    if (actionHandle.current) {
      // Restart the QTE when handle reach the end (360 degrees)
      if (handleRotation >= 361) {
        // eslint-disable-next-line no-use-before-define
        incrementEventCount('fail');
      } else {
        // Move the handle from 0 to 360 degrees
        actionHandle.current.style.transform = `rotate(${handleRotation}deg)`;
      }
    }
  }, [handleRotation]);

  // Start the QTE event
  function startQTE(type, progressResult) {
    const floorSpeed = qteDisplay === 2 ? 0.2 : 0.1;
    const speedProgession = qteDisplay === 2 ? 0.075 : 0.05;

    if ((qteDisplay === 1 || qteDisplay === 2) && stepProgression < 100) {
      if (type === 'success' && progressResult < 100) {
        handleSpeedRef.current += speedProgession;
      } else if (
        type === 'fail'
        && handleSpeedRef.current > floorSpeed
        && progressResult > 0
      ) {
        handleSpeedRef.current -= speedProgession;
      } else {
        handleSpeedRef.current = floorSpeed;
      }

      setTimeout(() => {
        previousTimeRef.current = new Date().getTime();
        setQteRun(true);
        spinHandle();
      }, 1000);
    }
  }

  // Reposition Target section
  function positionTarget() {
    const newTargetRotation = Math.random() * (360 - targetSuccessWidth - 120) + 120;
    setTargetRotation(newTargetRotation);
  }
  useEffect(() => {
    if (actionTarget.current) {
      actionTarget.current.style.transform = `rotate(${targetRotation}deg)`;
    }
  }, [targetRotation]);

  // stop the QTE event
  function stopQTE(type, progressResult) {
    cancelAnimationFrame(requestRef.current);
    setQteRun(false);

    // Reposition handle and target and restart QTE
    if (progressResult < 100 && (directiveActive === 1 || directiveActive === 2)) {
      setTimeout(() => {
        setQteResult(null);
        setHandleRotation(0);
        positionTarget();
        startQTE(type, progressResult);
      }, 1000);
    }
  }

  // Update the progress Bar
  async function incrementEventCount(type) {
    new Promise((resolve) => {
      const increment = 34;
      const progressResult = progressionHandler(type, increment);
      resolve(progressResult);
    }).then((progressResult) => {
      stopQTE(type, progressResult);
    });
  }

  // Handle the QTE stop action
  function stopTimeHandler() {
    if (
      handleRotation >= targetRotation
      && handleRotation <= targetRotation + targetSuccessWidth
    ) {
      incrementEventCount('success');
    } else {
      incrementEventCount('fail');
    }
  }

  // Trigger QTE
  useEffect(() => {
    if (directiveActive === 1) {
      setQteResult(null);
      handleSpeedRef.current = 0.1;
      positionTarget();
    } else if (directiveActive === 2) {
      setQteResult(null);
      setHandleRotation(0);
      handleSpeedRef.current = 0.2;
      positionTarget();
    } else {
      cancelAnimationFrame(requestRef.current);
    }
  }, [directiveActive]);
  useEffect(() => {
    if (qteDisplay === 1 || qteDisplay === 2) {
      startQTE();
    }
  }, [qteDisplay]);

  return (
    <div className='mt-20 lg:mt-32'>
      {(directiveActive === 1 || directiveActive === 2) && (
        <div
          className={`timed-action ${(qteDisplay === 1 || qteDisplay === 2) ? 'running' : 'paused'} state-${qteResult === true
            ? 'success'
            : qteResult === false
              ? 'fail'
              : 'default'
          }`}
        >
          <div className='timed-action-circle'>
            <SVG
              cacheRequests
              preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
              src={border}
              className='timed-action-circle-border'
            />
            <div ref={actionHandle} className='timed-action-handle'>
              <SVG
                cacheRequests
                preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
                src={handle}
                className='timed-action-handle-img'
              />
              <div className='timed-action-handle-glow' />
              <div className='timed-action-handle-glow reverse' />
            </div>
            <div
              ref={actionTarget}
              className={
                directiveActive === 2
                  ? 'timed-action-target-border'
                  : 'timed-action-target-border-large'
              }
            >
              <div
                className={
                  directiveActive === 2
                    ? 'timed-action-target'
                    : 'timed-action-target-large'
                }
              />
            </div>
            <QteButton
              ref={actionButton}
              onClick={() => stopTimeHandler()}
              label={strings.press ? strings.press : 'Press'}
              status={qteRun ? '' : 'disabled'}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default QteTimedAction;
