import React from 'react';

import SoundPlayer from '@elements/interactive-elements/sound-player';

function NavGarage({ pageContext }) {
  const strings = pageContext?.globalData?.strings?.edges?.[0]?.node;

  return (
    <div className='interactive-page-nav pointer-events-none absolute bottom-0 z-[2] flex w-full items-end justify-between px-6 pb-8 lg:px-12 lg:pb-12'>
      <SoundPlayer
        pageContext={pageContext}
        strings={strings?.labels}
        type='garage'
      />
    </div>
  );
}

export default NavGarage;
