import React, { useEffect, useContext, useState } from 'react';
import SVG from 'react-inlinesvg';
import { AnimatePresence, motion } from 'framer-motion';
import { useCookies } from 'react-cookie';

import { getPageURL } from '@base/utils/helpers/page-url';

import clickDragIcon from '@img/icons/click-drag.svg';
import pinchZoomIcon from '@img/icons/pinch-zoom.svg';
import { GateContext } from '@/utils/contexts/gate-context';
import { DeskContext } from '@/utils/contexts/desk-context';

function DirectiveModal(props) {
  const data = props.data?.nav;
  const [cookies, setCookie] = useCookies();
  const currentDomain = `${getPageURL('domain')}`;
  const cookieName = 'cofs-ftv-directives';
  const [manualAction, setManualAction] = useState(false);

  const { mobileState } = useContext(GateContext);
  const [isMobile, setIsMobile] = mobileState;

  const { directives } = useContext(DeskContext);
  const [directiveActive, setDirectiveActive] = directives;

  // Display Directives for first time visitors
  /*   useEffect(() => {
    if (!cookies[cookieName]) {
      setDirectiveActive('1')
    }
  }, [cookies]) */

  useEffect(() => {
    setDirectiveActive('1');
  }, []);

  // Play through directives automatically if users don't interact with them
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (manualAction && directiveActive !== '2') {
        return;
      }
      closeDirectives();
    }, 4500);
    return () => clearTimeout(timeoutId);
  }, [directiveActive, manualAction]);

  // Close Directive Modal
  const closeDirectives = () => {
    if (directiveActive === '1') {
      setDirectiveActive('2');
    }
    if (directiveActive === '2') {
      setDirectiveActive('0');
      // setCookie(cookieName, true, { domain: `${currentDomain}`, path: '/' })
    }
  };

  const manualCloseDirectives = () => {
    setManualAction(true);
    closeDirectives();
  };

  return (
    <AnimatePresence>
      {isMobile && directiveActive >= '1' && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3, delay: 0.25 }}
          className='modal-directives absolute z-1 flex h-full w-full cursor-pointer flex-col items-center justify-center'>
          {directiveActive === '1' && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className='pointer-events-none flex flex-col items-center justify-center'
            >
              <SVG
                cacheRequests
                preProcessor={(code) => code.replace(/stroke=".*?"/g, 'stroke="currentColor"')}
                src={clickDragIcon}
              />
              <span className='mt-4 w-48 text-center text-xs text-white'>
                {data?.clickDragDirective}
              </span>
            </motion.div>
          )}
          {directiveActive === '2' && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className='pointer-events-none flex flex-col items-center justify-center'>
              <SVG
                cacheRequests
                preProcessor={(code) => code.replace(/stroke=".*?"/g, 'stroke="currentColor"')}
                src={pinchZoomIcon}
              />
              <span className='mt-4 w-48 text-center text-xs text-white'>
                {data?.zoomDirective}
              </span>
            </motion.div>
          )}
          <div
            className='modal-directives-backdrop absolute left-0 top-0 z-under h-full w-full'
            onClick={manualCloseDirectives}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default DirectiveModal;
