import React, { useContext, useState, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { GarageContext } from '@/utils/contexts/garage-context';
import useQteProgression from '@/utils/helpers/qte-progression';
import QteButton from './button';

function QteButtonBurst({ strings }) {
  const { directives, steps, display, result } = useContext(GarageContext);
  const [directiveActive, setDirectiveActive] = directives;
  const [currentStep, setCurrentStep] = steps;
  const [qteDisplay, setQteDisplay] = display;
  const [qteResult, setQteResult] = result;
  const burstProgress = useRef(null);
  const timeoutRef = useRef(null);
  const intervalRef = useRef();
  const [runFirstTime, setRunFirstTime] = useState(true);
  const [qteRun, setQteRun] = useState(false);
  const [burstProgression, setBurstProgression] = useState(0);
  const completionTime = 5000; // Time allowed to complete the QTE before failing

  const progressionHandler = useQteProgression();

  const handleCancelTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const startQte = () => {
    setQteResult(null);
    setQteRun(true);
  };

  const resetQte = () => {
    handleCancelTimeout();
    setQteRun(false);
  };

  // Qte Status - Success or Fail
  const qteStatus = (status) => {
    if (status === 'success') {
      progressionHandler('success', 100);
      clearInterval(intervalRef.current);
      handleCancelTimeout();
      setQteRun(null);
    }
    if (status === 'fail') {
      clearInterval(intervalRef.current);
      handleCancelTimeout();
      progressionHandler('fail', 100);

      setTimeout(() => {
        resetQte();
      }, 1000);
    }
  };

  // Set Timer when QTE is running.
  // Or restart QTE when it is stopped
  useEffect(() => {
    if (qteRun) {
      timeoutRef.current = setTimeout(() => {
        qteStatus(burstProgression >= 100 ? 'success' : 'fail');
      }, completionTime);
    } else if (!qteRun && qteRun !== null && !runFirstTime) {
      setTimeout(() => {
        setBurstProgression(0);
        startQte();
      }, 1000);
    }
  }, [qteRun]);

  // Button Click Handler
  const burstHandler = () => {
    if (qteResult === null) {
      // Increase progression when clicking on the button
      setBurstProgression(burstProgression + 8);
      // Trigger success when progression reach the end
      if (burstProgression >= 100) {
        qteStatus('success');
      }
    }
  };
  useEffect(() => {
    const update = () => {
      // Trigger success when progression reach the end
      if (burstProgression >= 100) {
        qteStatus('success');
      }
      // Decrease progression when the button is not clicked
      if (burstProgression > 0 && burstProgression < 100) {
        setBurstProgression(burstProgression - 3);
      }
    };
    intervalRef.current = setInterval(update, 100);
    return () => clearInterval(intervalRef.current);
  }, [burstProgression]);

  // Display and Trigger the QTE
  useEffect(() => {
    if (directiveActive === 3) {
      setRunFirstTime(true);
      setQteResult(null);
      setBurstProgression(0);
    }
  }, [directiveActive]);

  useEffect(() => {
    if (qteDisplay === 3) {
      setRunFirstTime(false);
      startQte();
    }
  }, [qteDisplay]);

  return (
    <div className='mt-20 lg:mt-32'>
      {directiveActive === 3 && (
        <div className={`button-burst-wrapper ${(qteDisplay === 3) ? 'running' : 'paused'} state-${qteResult === true ? 'success' : qteResult === false ? 'fail' : 'default'} ${!qteRun && runFirstTime && 'no-animation'}`}>
          <AnimatePresence>
            {qteRun && !runFirstTime && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.25 }}
                className='button-burst-loading'
              />
            )}
          </AnimatePresence>
          <AnimatePresence>
            {qteRun && !runFirstTime && (
              <motion.div
                initial={{ opacity: 0, pointerEvents: 'none' }}
                animate={{
                  opacity: 1,
                  pointerEvents: 'auto',
                  transition: { duration: 0.5, delay: 1 },
                }}
                exit={{ opacity: 0, pointerEvents: 'none' }}
                transition={{ duration: 0.5 }}
                className='button-burst'
              >
                <div className='button-burst-progress-mask'>
                  <div
                    ref={burstProgress}
                    className={`button-burst-progress ${qteResult === null && (burstProgression <= 10 ? 'step1' : burstProgression <= 40 ? 'step2' : burstProgression <= 80 ? 'step3' : 'step4')}`}
                    aria-valuemin='0'
                    aria-valuemax='100'
                    style={{ '--value': `${burstProgression}%` }}
                  />
                </div>
                <QteButton
                  onClick={() => burstHandler()}
                  label={strings.click ? strings.click : 'Click'}
                  status={qteDisplay === 3 ? '' : 'disabled'}
                />
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {!qteRun && runFirstTime && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className='button-burst no-animation'
              >
                <div className='button-burst-progress-mask'>
                  <div
                    className='button-burst-progress step1'
                    aria-valuemin='0'
                    aria-valuemax='100'
                    style={{ '--value': '15%' }}
                  />
                </div>
                <QteButton
                  label={strings.press ? strings.press : 'Press'}
                  status='disabled'
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </div>
  );
}

export default QteButtonBurst;
