import React, { useEffect, useContext, useCallback } from 'react';
import SVG from 'react-inlinesvg';
import { AnimatePresence, motion } from 'framer-motion';
import { useCookies } from 'react-cookie';

import Heading from '@base/parts/heading';
import Markdown from '@base/parts/markdown';
import Image from '@base/parts/image';

import closeIcon from '@img/icons/close.svg';
import { InteractiveContext } from '@/utils/contexts/interactive-context';
import { DeskContext } from '@/utils/contexts/desk-context';

function ModalDesk(pageContext) {
  const [cookies, setCookie] = useCookies();
  const cookieName = 'cofs-endgame';

  const { endGame } = useContext(InteractiveContext);
  const [endGameActive, setEndGameActive] = endGame;

  const { modalOpen, modal, items, itemList } = useContext(DeskContext);
  const [isModalOpen, setModalOpen] = modalOpen;
  const [modalData, setModalData] = modal;
  const [foundItemList, setFoundItemList] = itemList;
  const [itemsToFind, setItemsToFind] = items;

  // Use right quotation marks depending of text localization
  const { locale } = pageContext.pageContext;
  const leftQuote = locale === 'fr' || locale === 'pt' || locale === 'es'
    ? '«&nbsp;'
    : locale === 'de'
      ? '„'
      : '“';
  const rightQuote = locale === 'fr' || locale === 'pt' || locale === 'es'
    ? '&nbsp;»'
    : locale === 'de'
      ? '“'
      : '”';

  // Close Modal
  const close = useCallback((itemsToFind, foundItemList, cookies) => {
    setModalOpen(false);

    // Trigger end-game modal when closing the last item
    if (foundItemList.length === itemsToFind && !cookies[cookieName]) {
      setTimeout(() => {
        setEndGameActive(true);
      }, 500);
    }
  }, []);

  const handleEscape = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        close(itemsToFind, foundItemList, cookies);
      }
    },
    [close],
  );

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('keydown', handleEscape, false);
    }
    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, [handleEscape, isModalOpen]);

  return (
    <AnimatePresence>
      {isModalOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className='modal-item absolute z-1 flex h-full w-full items-start justify-center'
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.25 }}
            className='modal-item-content mt-24 pb-14 lg:mt-44'
          >
            <button
              type='button'
              className='button-close absolute z-10 flex h-14 w-14 items-center justify-center'
              aria-label='close'
              onClick={() => close(itemsToFind, foundItemList, cookies)}
            >
              <SVG
                onError={(error) => console.log(error.message)}
                cacheRequests
                preProcessor={(code) => code.replace(/stroke=".*?"/g, 'stroke="currentColor"')}
                src={closeIcon}
                className='absolute'
              />
            </button>
            <div />
            <Heading
              text={modalData.title}
              tag='h3'
              className='reversed -mt-6 mb-20 px-16 text-center text-sm leading-normal sm:px-24 md:px-0 lg:text-lg lg:leading-tight xl:-mt-6'
            />
            <Image
              media={{ ...modalData.image, alternativeText: modalData.title }}
              className={`modal-item-image mx-auto mb-8 preview-${modalData.value}`}
            />
            <Markdown
              children={`${leftQuote}${modalData.description}${rightQuote}`}
              className='mx-auto px-8 text-center font-body text-sm leading-tight text-white lg:max-w-[75%] lg:text-base'
            />
          </motion.div>
          <div
            className='modal-item-backdrop absolute left-0 top-0 z-under h-full w-full'
            onClick={() => close(itemsToFind, foundItemList, cookies)}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default ModalDesk;
