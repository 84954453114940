import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import SVG from 'react-inlinesvg';

import categoryDisabled from '@img/map/ui/map-disabled-category.svg';
import CategoryIcon from './icon';

function NavMap({ data }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 1.2 }}
      className='map-nav'
    >
      <ul className='map-nav-list'>
        {data?.map((category, i) => (
          <li
            key={`map-element-${i}`}
            className={`map-category map-category-${category.value}`}
            value={category.value}
          >
            <CategoryIcon value={category.value} type='alt' />
            {category.title ? (
              category.title
            ) : (
              <SVG
                cacheRequests
                preProcessor={(code) => code.replace(/stroke=".*?"/g, 'stroke="currentColor"')}
                src={categoryDisabled}
                className='map-icon-disabled'
              />
            )}
          </li>
        ))}
        <StaticImage
          src='../../../assets/img/map/ui/map-nav-background-mobile.jpg'
          alt=''
          placeholder='blurred'
          loading='lazy'
          className='map-nav-background gatsby-hidden-desktop gatsby-absolute'
          role='presentation'
        />
      </ul>
      <StaticImage
        src='../../../assets/img/ui/polaroid-tape.png'
        alt=''
        placeholder='blurred'
        loading='lazy'
        className='gatsby-absolute gatsby-hidden-mobile -top-1 left-1/2 z-1 w-[35%] -translate-x-1/2 rotate-[4deg]'
        role='presentation'
      />
      <StaticImage
        src='../../../assets/img/map/ui/map-nav-background.png'
        alt=''
        placeholder='blurred'
        loading='lazy'
        className='map-nav-background gatsby-hidden-mobile'
        role='presentation'
      />
    </motion.div>
  );
}

export default NavMap;
