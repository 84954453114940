import React from 'react';
import SVG from 'react-inlinesvg';

import btnBorder from '@img/garage/qte-circle-border.svg';
import btnInnerCircle from '@img/garage/qte-button-inner-circle.svg';
import btnInnerBorder from '@img/garage/qte-button-inner-border.svg';

function QteButton({ ref, id, onClick, label, status }) {
  const buttonAction = () => {
    if (onClick) {
      const clickAction = onClick;
      clickAction();
    }
  };

  return (
    <button
      ref={ref}
      id={id}
      type='button'
      onClick={buttonAction}
      className={`qte-button ${status === 'disabled' && 'disabled'}`}
    >
      <span className='pointer-events-none'>{label}</span>
      <SVG
        cacheRequests
        src={btnInnerCircle}
        className='qte-button-circle pointer-events-none'
      />
      <SVG
        cacheRequests
        src={btnInnerBorder}
        className='qte-button-inner-border pointer-events-none'
      />
      <SVG
        cacheRequests
        src={btnInnerBorder}
        className='qte-button-inner-border-reverse pointer-events-none'
      />
      <SVG
        cacheRequests
        preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
        src={btnBorder}
        className='qte-button-border pointer-events-none'
      />
      <div className='qte-button-hitbox' />
    </button>
  );
}

export default QteButton;
