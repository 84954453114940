import successSound from '@assets/sounds/qte-success.mp3';
import failSound from '@assets/sounds/qte-fail.mp3';
import level1Sound from '@assets/sounds/item-found-level1.mp3';
import level2Sound from '@assets/sounds/item-found-level2.mp3';
import level3Sound from '@assets/sounds/item-found-level3.mp3';
import level4Sound from '@assets/sounds/item-found-level4.mp3';
import level5Sound from '@assets/sounds/item-found-level5.mp3';

export const soundEffects = (level) => {
  const soundEffectsPlayer = document.getElementById('sound-effects-player');
  const soundEffectsSource = document.getElementById('sound-effects-source');

  if (soundEffectsPlayer) {
    if (level === 'success') {
      // QTE Success Sound
      soundEffectsSource.src = successSound;
    } else if (level === 'fail') {
      // QTE Fail Sound
      soundEffectsSource.src = failSound;
    } else if (level === 1) {
      // Desk Level 1 Item Sound
      soundEffectsSource.src = level1Sound;
    } else if (level === 2) {
      // Desk Level 2 Item Sound
      soundEffectsSource.src = level2Sound;
    } else if (level === 3) {
      // Desk Level 3 Item Sound
      soundEffectsSource.src = level3Sound;
    } else if (level === 4) {
      // Desk Level 4 Item Sound
      soundEffectsSource.src = level4Sound;
    } else if (level === 5) {
      // Desk Complete Sound
      soundEffectsSource.src = level5Sound;
    } else {
      soundEffectsSource.src = level1Sound;
    }
    soundEffectsPlayer.load();
    soundEffectsPlayer.play();
  }
};
