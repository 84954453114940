import React, { useRef, useEffect } from 'react';
import useWindowSize from '@base/utils/hooks/useWindowSize';

import { getGeneralData } from '@api/general-data';

import HiddenElements from '@elements/interactive-desk/hidden-elements';
import NavDesk from '@elements/interactive-desk/nav';
import ModalDesk from '@elements/interactive-desk/modal';
import EndgameModal from '@elements/interactive-desk/endgame-modal';
import DirectiveModal from '@elements/interactive-desk/directives-modal';
import deskBackground from '@img/interactive-desk/interactive-desk-background.jpg';
import chain from '@img/interactive-desk/interactive-desk-chain.png';
import PinchZoom from '@/utils/helpers/pinch-zoom';
import DeskContextProvider from '@/utils/contexts/desk-context';

function DeskScene({ data, pageContext }) {
  const deskWrapper = useRef(null);
  const desk = useRef(null);
  const generalData = getGeneralData(pageContext, data);
  const windowSize = useWindowSize();
  const { globalData } = generalData;

  // Center scene in viewport when window size change
  const centerScene = () => {
    const wrapperHeight = deskWrapper.current.clientHeight;
    const wrapperWidth = deskWrapper.current.clientWidth;

    deskWrapper.current.scroll({
      top: (desk.current.clientHeight - wrapperHeight) / 2,
      left: (desk.current.clientWidth - wrapperWidth) / 2,
      behavior: 'instant',
    });
  };
  useEffect(() => {
    setTimeout(() => {
      centerScene();
    }, 500);
  }, [windowSize]);

  // Pinch Zoom
  useEffect(() => {
    const pzOptions = {
      draggableUnzoomed: true,
      minZoom: 1,
      maxZoom: 2,
      useMouseWheel: false,
    };
    const pz = new PinchZoom(deskWrapper.current, pzOptions);
    pz.enable();
  }, []);

  return (
    <section className='interactive-section interactive-desk-container'>
      <DeskContextProvider>
        <div ref={deskWrapper} className='interactive-desk'>
          <div ref={desk} className='desk-scene'>
            <img
              src={deskBackground}
              className='desk-image pointer-events-none left-0 top-0'
              alt='Frank Stone’s Workbench'
            />
            <div className='desk-chains'>
              <img
                src={chain}
                className='desk-chain pointer-events-none'
                alt='Chains'
              />
            </div>
            <HiddenElements
              data={data.pageData.hiddenItems}
              pageContext={pageContext}
            />
            <div className='desk-light'>
              <div className='desk-light-bulb' />
              <div className='desk-light-beam' />
            </div>
            <div className='particles-background'>
              <div className='particle particles-first' />
              <div className='particle particles-second' />
              <div className='particle particles-third' />
            </div>
          </div>
        </div>
        <ModalDesk pageContext={pageContext} />
        <NavDesk pageContext={pageContext} data={data.pageData} />
        <EndgameModal
          pageContext={pageContext}
          globalData={globalData}
          data={data.pageData}
        />
        <DirectiveModal data={data.pageData} />
      </DeskContextProvider>
      <div className='hint-borders'>
        <div className='hint-border-top hint-horizontal' />
        <div className='hint-border-bottom hint-horizontal' />
        <div className='hint-border-left hint-vertical' />
        <div className='hint-border-right hint-vertical' />
      </div>
    </section>
  );
}
export default DeskScene;
