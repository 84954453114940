import React, { useContext, useEffect } from 'react';

import useWindowSize from '@base/utils/hooks/useWindowSize';
import Image from '@base/parts/image';
import FooterNav from '@base/elements/nav/nav-footer';
import Copyrights from '@base/elements/copyrights';
import Ratings from '@base/elements/ratings';

import { GateContext } from '@/utils/contexts/gate-context';

function FooterSmall({ pageContext, globalData, className }) {
  const footerNav = globalData?.navData?.footerNav ? globalData?.navData?.footerNav : pageContext.globalData?.navigation?.edges?.[0]?.node?.footerNav;
  const footer = globalData?.globalData?.footer ? globalData?.globalData?.footer : globalData?.footerData;
  const theme = pageContext?.theme?.siteTheme?.footer ? pageContext?.theme?.siteTheme?.footer : pageContext?.siteTheme?.footer;
  const additionnalLogo = footer?.additionnalLogo ? footer?.additionnalLogo : globalData.footerData?.additionnalLogo;
  const additionnalLogoLink = footer?.additionnalLogoLink ? footer?.additionnalLogoLink : globalData.footerData?.additionnalLogoLink;
  const brand = globalData?.brandData ? globalData?.brandData : pageContext.globalData?.brand;
  const labels = globalData.stringsData?.labels ? globalData.stringsData?.labels : globalData.stringsLabels;

  // Set Mobile State
  const { mobileState } = useContext(GateContext);
  const [isMobile, setIsMobile] = mobileState;
  const windowSize = useWindowSize();
  useEffect(() => {
    if (windowSize.width <= 991) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize]);

  return (
    <footer className={`footer-small bg-secondary lg:bg-black bottom-0 left-0 w-full pt-5 lg:pt-0 ${className}`}>
      {labels?.logoTagLine && (
        <span className='footer-tagline flex w-full items-center justify-center whitespace-nowrap font-tagLine text-xs font-bold uppercase text-white lg:absolute lg:-top-20 lg:text-base'>
          {pageContext.locale === 'ja' ? (
            <>
              <Image
                media={brand?.tagLineLogo}
                className='h-[1.4rem] lg:h-[2rem] w-auto mr-3 mt-[1px]'
              />
              {labels?.logoTagLine}
            </>
          ) : (
            <>
              {labels?.logoTagLine}
              <Image
                media={brand?.tagLineLogo}
                className='h-[1.4rem] lg:h-[1.9rem] w-auto ml-3 -mt-[2px]'
              />
            </>
          )}

        </span>
      )}
      <div className='footer-nav'>
        {footerNav && footerNav !== null && (
          <FooterNav
            links={footerNav}
            logo={additionnalLogo}
            logoLink={additionnalLogoLink}
            theme={theme.FooterNav}
          />
        )}
        <div className='footer-ratings'>
          <Ratings
            data={globalData.footerData}
            locale={pageContext.locale}
          />
        </div>
        {footer?.legalText && (
          <Copyrights
            className='footer-nav-copyrights tracking-wider lg:text-center uppercase leading-normal text-white'
            startYear={footer.startYear}
            text={footer.legalText}
          />
        )}
      </div>
    </footer>
  );
}

export default FooterSmall;
