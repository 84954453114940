import React, { useRef, useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import SVG from 'react-inlinesvg';

import useWindowSize from '@base/utils/hooks/useWindowSize';
import Image from '@base/parts/image';

import visitedIcon from '@img/map/icons/map-icon-visited.svg';
import arrowUp from '@img/map/ui/map-arrow-up.svg';
import arrowDown from '@img/map/ui/map-arrow-down.svg';
import arrowLeft from '@img/map/ui/map-arrow-left.svg';
import getLocationContent from '@/utils/helpers/get-location-content';
import { MapContext } from '@/utils/contexts/map-context';
import CategoryIcon from './icon';

function MapMarkers({ data, pageContext }) {
  const elements = useRef(null);
  const windowSize = useWindowSize();
  const { elementsList } = useContext(MapContext);
  const [clickedElementsList, setClickedElementsList] = elementsList;
  const locationContentHandler = getLocationContent();

  return (
    <div
      ref={elements}
      className={`map-elements-wrapper map-elements-${pageContext.locale}`}
    >
      {data?.map((element, i) => (
        <button
          type='button'
          key={`map-element-${i}`}
          className={`map-element p-0 map-element-${element.category}`}
          onClick={
            element.category !== 'disabled'
              ? () => locationContentHandler(event, data, element.value, pageContext, `${windowSize.width <= 991 ? 'mobile' : 'desktop'}`, elements)
              : null
          }
          disabled={element.category === 'disabled'}
          value={element.value}
        >
          <CategoryIcon value={element.category} type='main' />
          {clickedElementsList?.find((listElement) => listElement === element.value) && (
            <SVG
              cacheRequests
              src={visitedIcon}
              className='map-element-visited absolute'
            />
          )}
          {element.category === 'landmarks' && (
            <>
              <span className='map-element-title'>
                {element.altTitle || element.title}
              </span>
              <SVG
                cacheRequests
                src={element.value === 'calhern-pharmacy' ? arrowUp : (element.value === 'cedar-forest' ? arrowLeft : arrowDown)}
                className='map-element-arrow absolute'
              />
            </>
          )}
          {element.category === 'points-of-interest' && (
            <>
              <span className='map-element-title'>{element.title}</span>
              {element.image && (
                <motion.div
                  initial={{ opacity: 0, translateX: -20, rotate: 0 }}
                  animate={{ opacity: 1, translateX: 0, rotate: element.value === 'garage-hangout' ? -7 : 3 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5, delay: 1.8 }}
                  className='map-element-image gatsby-absolute'
                >
                  <Image
                    media={element.image}
                    alt={element.altTitle || element.title}
                    className='image'
                  />
                  <motion.div
                    initial={{ opacity: 0, translateY: -20 }}
                    animate={{ opacity: 1, translateY: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.25, delay: 2 }}
                    className='image-pin'
                  >
                    <StaticImage
                      src='../../../assets/img/map/ui/map-push-pin.png'
                      alt=''
                      placeholder='blurred'
                      loading='lazy'
                      role='presentation'
                    />
                  </motion.div>
                  <StaticImage
                    src='../../../assets/img/ui/polaroid-border.jpg'
                    alt=''
                    placeholder='blurred'
                    loading='lazy'
                    className='image-border'
                    role='presentation'
                  />
                  <span className='map-element-altTitle'>
                    {element.altTitle || element.title}
                  </span>
                </motion.div>
              )}
            </>
          )}
        </button>
      ))}
    </div>
  );
}

export default MapMarkers;
