import React, { useEffect, useContext } from 'react'
import { motion } from 'framer-motion'

import useLocalStorage from '@base/utils/hooks/useLocalStorage'
import triggerEventClick from '@base/utils/helpers/tracking/trigger-event-click'

import { InteractiveContext } from '@/utils/contexts/interactive-context'
import { DeskContext } from '@/utils/contexts/desk-context'
import { soundEffects } from '@/utils/helpers/sound-effects'
import ItemMask from './item-mask'

const HiddenElements = ({ data, pageContext }) => {
  const { soundActive } = useContext(InteractiveContext)
  const [isSoundActive, setSoundActive] = soundActive
  const { modalOpen, modal, items, itemList } = useContext(DeskContext)
  const [isModalOpen, setModalOpen] = modalOpen
  const [modalData, setModalData] = modal
  const [foundItemList, setFoundItemList] = itemList
  const [itemsToFind, setItemsToFind] = items

  // Set the amount of items to find
  useEffect(() => {
    if (data?.length) {
      setItemsToFind(data.length)
    }
  }, [foundItemList])

  // Display Item Modal Box
  const displayItemInformation = (e) => {
    const item = e.target?.attributes?.value?.value
    if (item) {
      // Save item to found items list
      const itemData = data.find(x => x.value === item)
      if (!foundItemList.find(listItem => listItem === itemData.value)) {
        setFoundItemList([...foundItemList, itemData.value])
        useLocalStorage('set', 'items-found', JSON.stringify([...foundItemList, itemData.value]))
      }

      // Open Modal box
      window?.scrollTo({ top: 0, behavior: 'smooth' });
      setModalData(itemData)
      setModalOpen(true)

      // Play Sound Effect
      if (isSoundActive) {
        soundEffects(itemData?.level)
      }

      // Send tracking data to the dataLayer
      let dataLayer = {
        pageContext: pageContext,
        event: itemData?.eventAction,
        page: 'interactive_desk',
      }
      triggerEventClick(dataLayer)
    }
  }

  // Add Class on Hover
  const hoverItem = (item, state) => {
    if (state === 'enter') {
      document.querySelector(`.hidden-element-state[value='${item}']`).classList.add('hover')
    } else {
      document.querySelector(`.hidden-element-state[value='${item}']`).classList.remove('hover')
    }
  }

  return (
    <>
      <div className="hidden-elements-wrapper absolute top-0 left-0 w-full h-full">
        {data?.map((item, i) => (
          <div
            key={`hidden-element-${i}`}
            className="hidden-element"
            onClick={displayItemInformation}
            onMouseEnter={() => hoverItem(item.value, 'enter')}
            onMouseLeave={() => hoverItem(item.value, 'leave')}
            value={item.value}
          >
            <div className="hidden-element-glow" />
            <ItemMask value={item.value} />
          </div>
        ))}
      </div>
      <div className="hidden-elements-states">
        {data?.map((item, i) => (
          <div
            key={`hidden-element-state-${i}`}
            className={`hidden-element-state transition-all duration-500 ${isModalOpen && modalData?.value === item.value && modalData?.value !== 'meat-hook' ? 'opacity-0 delay-0' : 'opacity-100 delay-500'}`}
            value={item.value}
          >
            {foundItemList?.find(listItem => listItem === item.value) && (
              <motion.i
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.5, delay: 1 } }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="hidden-element-found"
              />
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default HiddenElements
