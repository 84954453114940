import React, { useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { GarageContext } from '@/utils/contexts/garage-context';

function LockBox() {
  const { directives, steps } = useContext(GarageContext);
  const [currentStep, setCurrentStep] = steps;
  const [directiveActive, setDirectiveActive] = directives;

  return (
    <div className={`lockbox pointer-events-none absolute bottom-16 ml-[10%] w-[35%] select-none lockbox-step-${currentStep}`}>
      <div className='lockbox-top'>
        <StaticImage
          src='../../../assets/img/garage/box-top-1.png'
          alt=''
          placeholder='blurred'
          loading='lazy'
          className={`top step1 ${currentStep <= 1 && 'open'}`}
          role='presentation'
        />
        <StaticImage
          src='../../../assets/img/garage/box-top-2.png'
          alt=''
          placeholder='blurred'
          loading='lazy'
          className={`top step2 ${currentStep === 2 && 'open'}`}
          role='presentation'
        />
        <StaticImage
          src='../../../assets/img/garage/box-top-3.png'
          alt=''
          placeholder='blurred'
          loading='lazy'
          className={`top step3 ${currentStep === 3 && 'open'}`}
          role='presentation'
        />
        <StaticImage
          src='../../../assets/img/garage/box-top-4.png'
          alt=''
          placeholder='blurred'
          loading='lazy'
          className={`top step4 ${currentStep === 4 && 'open'}`}
          role='presentation'
        />
      </div>
      <StaticImage
        src='../../../assets/img/garage/box-bottom.png'
        alt=''
        placeholder='blurred'
        loading='lazy'
        className='lockbox-bottom'
        role='presentation'
      />
      <StaticImage
        src='../../../assets/img/garage/lockbox-picture-model.png'
        alt=''
        placeholder='blurred'
        loading='lazy'
        className={`lockbox-picture ${directiveActive === 4 && 'hide'}`}
        role='presentation'
      />
      {directiveActive === 4 && (
        <div className='lockbox-picture-wrapper'>
          <StaticImage
            src='../../../assets/img/garage/lockbox-picture.jpg'
            alt=''
            placeholder='blurred'
            loading='lazy'
            className='lockbox-picture-full'
            role='presentation'
          />
        </div>
      )}
    </div>
  );
}

export default LockBox;
