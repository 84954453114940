import React, { useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { AnimatePresence, motion } from 'framer-motion';

import triggerEventClick from '@base/utils/helpers/tracking/trigger-event-click';
import Markdown from '@base/parts/markdown';

import { GarageContext } from '@/utils/contexts/garage-context';
import triggerPageView from '@/utils/helpers/trigger-page-view';

function QteGuide({ data, pageContext, strings }) {
  const { directives, progression, steps, display, result } = useContext(GarageContext);
  const [directiveActive, setDirectiveActive] = directives;
  const [stepProgression, setStepProgression] = progression;
  const [currentStep, setCurrentStep] = steps;
  const [qteDisplay, setQteDisplay] = display;
  const [qteResult, setQteResult] = result;

  // Trigger the QTE
  const triggerQTE = (step) => {
    setQteResult(null);
    setCurrentStep(step);
    setQteDisplay(step);
    setStepProgression(0);

    // Send tracking data to the dataLayer
    let eventAction = '';
    if (step === 1) {
      eventAction = 'how_to_1';
    }
    if (step === 3) {
      eventAction = 'how_to_2';
    }
    const dataLayer = {
      pageContext,
      event: eventAction,
      page: 'garage_hangout',
    };
    if (eventAction) {
      triggerEventClick(dataLayer);
    }

    // Endgame pageview
    if (step === 4) {
      triggerPageView('garage_hangout_final', pageContext);
    }
  };

  return (
    <AnimatePresence>
      {directiveActive && qteDisplay !== directiveActive && (
        (directiveActive === 1 && data.descriptionStep1) || (directiveActive === 2 && data.descriptionStep2) || (directiveActive === 3 && data.descriptionStep3) ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
            className='qte-guide mt-20 lg:mt-32'
          >
            {directiveActive === 1 && (
              <Markdown type='longText'>{data.descriptionStep1}</Markdown>
            )}
            {directiveActive === 2 && (
              <Markdown type='longText'>{data.descriptionStep2}</Markdown>
            )}
            {directiveActive === 3 && (
              <Markdown type='longText'>{data.descriptionStep3}</Markdown>
            )}

            <button
              type='button'
              className='btn button larger narrow'
              onClick={() => triggerQTE(directiveActive)}
            >
              <span>
                {strings.start ? strings.start : 'Start'}
                <StaticImage
                  src='../../../../assets/img/icons/arrow.png'
                  alt=''
                  className='icon-arrow ml-3 flex'
                  placeholder='blurred'
                  loading='lazy'
                />
              </span>
            </button>
          </motion.div>
        ) : (
          triggerQTE(directiveActive)
        )
      )}
    </AnimatePresence>
  );
}

export default QteGuide;
