/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useContext, useEffect } from 'react';
import SVG from 'react-inlinesvg';

import useLocalStorage from '@base/utils/hooks/useLocalStorage';
import triggerEventClick from '@base/utils/helpers/tracking/trigger-event-click';

import soundtrack from '@assets/sounds/cedar-steel-music-loop.mp3';
import soundIcon from '@img/icons/sound.svg';
import { GateContext } from '@/utils/contexts/gate-context';
import { InteractiveContext } from '@/utils/contexts/interactive-context';

function SoundPlayer({ pageContext, strings, type }) {
  const audio = useRef(null);
  const { soundActive, endGame } = useContext(InteractiveContext);
  const [isSoundActive, setSoundActive] = soundActive;
  const [endGameActive, setEndGameActive] = endGame;
  const { firstTime } = useContext(GateContext);
  const [isFirstTime, setFirstTime] = firstTime;

  // Play & Pause audio with fade in/out effect
  const audioFade = (status) => {
    const steps = 10;
    const fadeTime = 500;
    let i = 0;
    const interval = fadeTime / steps;

    if (status === 'play') {
      audio.current.play();
    }

    // Fade in/out audio
    const intervalId = setInterval(() => {
      const volumeIncrement = (0.4 / steps) * i;
      const volume = status === 'play' ? (0.4 / steps) * i : (4 / steps) - volumeIncrement;
      audio.current.volume = volume;
      if (++i >= steps) {
        if (status === 'pause' && i === steps) {
          audio.current.pause();
        }
        clearInterval(intervalId);
      }
    }, interval);
  };

  // Play/Pause the Sound effects
  const handleSound = () => {
    if (isSoundActive) {
      useLocalStorage('set', 'sound-enabled', 'false');
      setSoundActive(false);
      audioFade('pause');
    } else {
      useLocalStorage('set', 'sound-enabled', 'true');
      setSoundActive(true);
      audioFade('play');

      const dataLayer = {
        pageContext,
        event: 'sound_on',
        page: type === 'garage' ? 'garage_hangout' : 'interactive_desk',
      };
      triggerEventClick(dataLayer);
    }
  };

  // Pause the background soundtrack when endgame is active
  useEffect(() => {
    if (endGameActive && isSoundActive) {
      audioFade('pause');
    } else if (isSoundActive) {
      // audioFade('play')
    }
  }, [endGameActive]);

  // Autoplay when user interact with page (when page reload)
  useEffect(() => {
    if (isSoundActive && !isFirstTime) {
      audio.current.play().catch((e) => {
        window.addEventListener('click', () => {
          audio.current.play();
        }, { once: true });
      });
    }
  }, []);

  return (
    <>
      <button
        type='button'
        className={`sound-player pointer-events-auto mb-2 flex items-center lg:mb-0 ${isSoundActive ? 'playing' : ''}`}
        onClick={() => handleSound()}
      >
        <SVG
          cacheRequests
          preProcessor={(code) => code.replace(/stroke=".*?"/g, 'stroke="currentColor"')}
          src={soundIcon}
          className='mr-3 h-auto w-12 overflow-visible text-white lg:w-auto'
        />
        <span className='hidden font-body text-xs font-black uppercase text-white lg:flex'>
          {isSoundActive ? strings.soundOn : strings.soundOff}
        </span>
      </button>
      <audio ref={audio} loop autoPlay={!!(isSoundActive && !isFirstTime)}>
        {type === 'garage' ? (
          <source src='' type='audio/mpeg' />
        ) : (
          <source src={soundtrack} type='audio/mpeg' />
        )}
      </audio>
      <audio id='sound-effects-player'>
        <source id='sound-effects-source' src='' type='audio/mpeg' />
      </audio>
    </>
  );
}

export default SoundPlayer;
